import React from 'react';
import PropTypes from 'prop-types';

import styles from './ConfirmationModal.module.scss';
import Button from '../../Button';

class ConfirmationModal extends React.Component {
  
  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  confirm() {
    if(this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  cancel() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    const { confirmText, cancelText } = this.props;
    return (
      <div className={styles.confirmationModal}>
        <div className={styles.content}>
          <div className={styles.title}>Warning</div>
          <div className={styles.text}>
            {this.props.text}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={this.confirm}
              ctaHighlight={false}>
              {confirmText}
            </Button>
            <Button
              onClick={this.cancel}>
              {cancelText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
    
ConfirmationModal.propTypes = {
  text: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
};

ConfirmationModal.defaultProps = {
  confirmText: 'Yes',
  cancelText: 'No'
};

export default ConfirmationModal;