import act_1  from '../assets/act-1.pdf';
import act_2  from '../assets/act-2.pdf';
import act_3  from '../assets/act-3.pdf';
import act_4  from '../assets/act-4.pdf';
import act_5 from '../assets/act-5.pdf';
import act_6 from '../assets/act-6.pdf';
import act_7 from '../assets/act-7.pdf';
import act_8 from '../assets/act-8.pdf';
import sat_1 from '../assets/sat-1.pdf';
import sat_2 from '../assets/sat-2.pdf';
import sat_3 from '../assets/sat-3.pdf';
import sat_4 from '../assets/sat-4.pdf';
import sat_5 from '../assets/sat-5.pdf';
import sat_6 from '../assets/sat-6.pdf';
import sat_7 from '../assets/sat-7.pdf';
import sat_8 from '../assets/sat-8.pdf';

export function testMapper(testType, index) {
  testType  = testType.toLowerCase();
  index  = parseInt(index);
  if(testType === 'sat') {
    if(index === 1) {
      return SAT_1;
    }
    if(index === 2) {
      return SAT_2;
    }
    if(index === 3) {
      return SAT_3;
    }
    if(index === 4) {
      return SAT_4;
    }
    if(index === 6) {
      return SAT_5;
    }
    if(index === 7) {
      return SAT_6;
    }
    if(index === 8) {
      return SAT_7;
    }
    if(index === 9) {
      return SAT_8;
    }
  }
  if(testType === 'act') {
    if(index === 1) {
      return ACT_1;
    }
    if(index === 2) {
      return ACT_2;
    }
    if(index === 3) {
      return ACT_3;
    }
    if(index === 4) {
      return ACT_4;
    }
    if(index === 5) {
      return ACT_5;
    }
    if(index === 6) {
      return ACT_6;
    }
    if(index === 7) {
      return ACT_7;
    }
    if(index === 8) {
      return ACT_8;
    }
  } 
  return null;
}

export function testNameMapper(testName) {
  if(testName === 'RedbookTest1') {
    return ACT_1;
  }
  if(testName === 'RedbookTest2') {
    return ACT_2;
  }
  if(testName === 'RedbookTest3') {
    return ACT_3;
  }
  if(testName === 'RedbookTest4') {
    return ACT_4;
  }
  if(testName === 'RedbookTest5') {
    return ACT_5;
  }
  if(testName === 'ACTPrepGuideTest1') {
    return ACT_6;
  }
  if(testName === 'ACTPrepGuideTest2') {
    return ACT_7;
  }
  if(testName === 'ACTPrepGuideTest3') {
    return ACT_8;
  }
  if(testName === 'SATTest1') {
    return SAT_1;
  }
  if(testName === 'SATTest2') {
    return SAT_2;
  }
  if(testName === 'SATTest3') {
    return SAT_3;
  }
  if(testName === 'SATTest4') {
    return SAT_4;
  }
  if(testName === 'SATTest5') {
    return SAT_5;
  }
  if(testName === 'SATTest6') {
    return SAT_6;
  }
  if(testName === 'SATTest7') {
    return SAT_7;
  }
  if(testName === 'SATTest8') {
    return SAT_8;
  }
}

export function testNames() {
  let test_names = []
  test_names.push(ACT_1.testName.split(' ').join(''));
  test_names.push(ACT_2.testName.split(' ').join(''));
  test_names.push(ACT_3.testName.split(' ').join(''));
  test_names.push(ACT_4.testName.split(' ').join(''));
  test_names.push(ACT_5.testName.split(' ').join(''));
  test_names.push(ACT_6.testName.split(' ').join(''));
  test_names.push(ACT_7.testName.split(' ').join(''));
  test_names.push(ACT_8.testName.split(' ').join(''));
  test_names.push(SAT_1.testName.split(' ').join(''));
  test_names.push(SAT_2.testName.split(' ').join(''));
  test_names.push(SAT_3.testName.split(' ').join(''));
  test_names.push(SAT_4.testName.split(' ').join(''));
  test_names.push(SAT_5.testName.split(' ').join(''));
  test_names.push(SAT_6.testName.split(' ').join(''));
  test_names.push(SAT_7.testName.split(' ').join(''));
  test_names.push(SAT_8.testName.split(' ').join(''));
  return test_names.join('|')
}

export const ACT_1= {
  testType: 'ACT',
  testIndex: '1', 
  testName: 'Redbook Test 1',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_1,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_1_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_1_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 28,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_1_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 29,
      pdfEnd: 36,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_1_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 37,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_2= {
  testType: 'ACT',
  testIndex: '2', 
  testName: 'Redbook Test 2',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_2,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_2_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_2_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 28,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_2_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 29,
      pdfEnd: 36,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_2_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 37,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_3= {
  testType: 'ACT',
  testIndex: '3', 
  testName: 'Redbook Test 3',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_3,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_3_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 11,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_3_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 12,
      pdfEnd: 27,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_3_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 28,
      pdfEnd: 35,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_3_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 36,
      pdfEnd: 47,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_4= {
  testType: 'ACT',
  testIndex: '4', 
  testName: 'Redbook Test 4',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_4,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_4_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_4_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 28,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_4_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 29,
      pdfEnd: 36,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_4_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 37,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_5= {
  testType: 'ACT',
  testIndex: '5', 
  testName: 'Redbook Test 5',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_5,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_5_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_5_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 28,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_5_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 29,
      pdfEnd: 36,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_5_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 37,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_6= {
  testType: 'ACT',
  testIndex: '6', 
  testName: 'ACT Prep Guide Test 1',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_6,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_6_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_6_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 30,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_6_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 31,
      pdfEnd: 38,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_6_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 39,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_7= {
  testType: 'ACT',
  testIndex: '7', 
  testName: 'ACT Prep Guide Test 2',
  formCode: '16MC2',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_7,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_7_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_7_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 30,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_7_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 31,
      pdfEnd: 38,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_7_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 39,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const ACT_8= {
  testType: 'ACT',
  testIndex: '8', 
  testName: 'ACT Prep Guide Test 3',
  formCode: '16MC3',
  testSectionCount: 4,
  totalTime: '2h 55m + 10m break',
  totalTimeExtended: '4h 30m + 15m break',
  doubleTimeExtended: '5h 50m + 20m break',
  testPDF: act_8,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'ACT_8_ENGLISH',
      subject: 'english',
      time: 2700, //45 minutes
      extendedTime: 4200, //70 minutes
      doubleTime: 5400, //90 minutes
      pdfStart: 1,
      pdfEnd: 12,
      // itinerary_key: 'rbg-030101',
      questionCount: 75,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
    },
    {
      stepIndex: 1,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'ACT_8_MATH',
      subject: 'math',
      time: 3600, //60 minutes
      extendedTime: 5400, //90 minutes
      doubleTime: 7200, //120 minutes
      pdfStart: 13,
      pdfEnd: 30,
      // itinerary_key: 'rbg-030102',
      questionCount: 60,     
      answerChoices: [['A', 'B', 'C', 'D', 'E'], ['F', 'G', 'H', 'J', 'K']],
      isNextStepBreak: true
    },
    {
      stepIndex: 2,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'ACT_8_READING',
      subject: 'reading',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 31,
      pdfEnd: 38,
      // itinerary_key: 'rbg-030103',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']]
    },
    {
      stepIndex: 4,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'ACT_8_SCIENCE',
      subject: 'science',
      time: 2100, //35 minutes
      extendedTime: 3300, //55 minutes
      doubleTime: 4200, // 70 minutes
      pdfStart: 39,
      pdfEnd: 50,
      // itinerary_key: 'rbg-030104',
      questionCount: 40,
      answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
      isLastSection: true
    },
  ]
};

export const SAT_1= {
  testType: 'SAT',
  testIndex: '1', 
  testName: 'SAT Test 1',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_1,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_1_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 15,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_1_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 16,
      pdfEnd: 30,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_1_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 31,
      pdfEnd: 37,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_1_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 38,
      pdfEnd: 52,
      // itinerary_key: 'rbg-030104',      doubleTime: 1200, //20 minutes
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
};

export const SAT_2= {
  testType: 'SAT',
  testIndex: '2', 
  testName: 'SAT Test 2',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_2,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_2_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 15,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_2_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 16,
      pdfEnd: 29,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_2_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 30,
      pdfEnd: 37,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_2_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 38,
      pdfEnd: 51,
      // itinerary_key: 'rbg-030104',      doubleTime: 1200, //20 minutes
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
};

export const SAT_3= {
  testType: 'SAT',
  testIndex: '3', 
  testName: 'SAT Test 3',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_3,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_3_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 14,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_3_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 15,
      pdfEnd: 30,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_3_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 31,
      pdfEnd: 38,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_3_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 39,
      pdfEnd: 54,
      // itinerary_key: 'rbg-030104',      doubleTime: 1200, //20 minutes
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
};

export const SAT_4= {
  testType: 'SAT',
  testIndex: '4', 
  testName: 'SAT Test 4',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_4,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_4_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 14,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_4_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 15,
      pdfEnd: 28,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_4_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 29,
      pdfEnd: 36,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_4_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 37,
      pdfEnd: 53,
      // itinerary_key: 'rbg-030104',      doubleTime: 1200, //20 minutes
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
};

export const SAT_5= {
  testType: 'SAT',
  testIndex: '6', 
  testName: 'SAT Test 5',
  formCode: '16MC1',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_5,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_6_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 20,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_6_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 21,
      pdfEnd: 35,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_6_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 36,
      pdfEnd: 44,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_6_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 45,
      pdfEnd: 62,
      // itinerary_key: 'rbg-030104',      doubleTime: 1200, //20 minutes
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
};

export const SAT_6= {
  testType: 'SAT',
  testIndex: '7', 
  testName: 'SAT Test 6',
  formCode: '16MC2',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_6,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_7_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 20,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_7_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 21,
      pdfEnd: 37,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_7_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 38,
      pdfEnd: 46,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_7_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 47,
      pdfEnd: 64,
      // itinerary_key: 'rbg-030104',
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
}

export const SAT_7= {
  testType: 'SAT',
  testIndex: '8', 
  testName: 'SAT Test 7',
  formCode: '16MC3',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_7,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_8_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 17,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_8_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 18,
      pdfEnd: 31,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_8_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 32,
      pdfEnd: 40,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_8_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 41,
      pdfEnd: 56,
      // itinerary_key: 'rbg-030104',
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
}

export const SAT_8= {
  testType: 'SAT',
  testIndex: '9', 
  testName: 'SAT Test 8',
  formCode: '16MC4',
  testSectionCount: 4,
  totalTime: '3h + 15m break',
  totalTimeExtended: '4h 45m + 25m break',
  doubleTimeExtended: '6h + 30m break',
  testPDF: sat_8,
  steps: [
    {
      stepIndex: 0,
      type: 'test',
      sectionIndex: 1,
      testSectionId: 'SAT_9_READING',
      subject: 'reading',
      time: 3900, //65 minutes
      extendedTime: 6000, //100 minutes
      doubleTime: 7800, //130 minutes
      pdfStart: 1,
      pdfEnd: 16,
      // itinerary_key: 'rbg-030101',
      questionCount: 52,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
    },
    {
      stepIndex: 1,
      type: 'break',
      subject: '10-15 minute break',
      time: 600, //10 minutes
      extendedTime: 900, //15 minutes
      doubleTime: 1200, //20 minutes
    },
    {
      stepIndex: 2,
      type: 'test',
      sectionIndex: 2,
      testSectionId: 'SAT_9_WRITING',
      subject: 'writing and language',
      time: 2100, //35 minutes
      extendedTime: 3600, //60 minutes
      doubleTime: 4200, //70 minutes
      pdfStart: 17,
      pdfEnd: 34,
      // itinerary_key: 'rbg-030102',
      questionCount: 44,     
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isNextStepBreak: true
    },
    {
      stepIndex: 3,
      type: 'test',
      sectionIndex: 3,
      testSectionId: 'SAT_9_MATH_NC',
      subject: 'math no calculator',
      gridin: [16, 20],
      time: 1500, //25 minutes
      extendedTime: 2400, //40 minutes
      doubleTime: 3000, //50 minutes
      pdfStart: 35,
      pdfEnd: 42,
      // itinerary_key: 'rbg-030103',
      questionCount: 20,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']]
    },
    {
      stepIndex: 4,
      type: 'break',
      subject: '5-10 minute break',
      time: 300, //5 minutes
      extendedTime: 600, //10 minutes
      doubleTime: 600, //10 minutes
    },
    {
      stepIndex: 5,
      type: 'test',
      sectionIndex: 4,
      testSectionId: 'SAT_9_MATH',
      subject: 'math calculator',
      gridin: [31, 38],
      time: 3300, //55 minutes
      extendedTime: 5100, //85 minutes
      doubleTime: 6600, //110 minutes
      pdfStart: 43,
      pdfEnd: 60,
      // itinerary_key: 'rbg-030104',
      questionCount: 38,
      answerChoices: [['A', 'B', 'C', 'D'], ['A', 'B', 'C', 'D']],
      isLastSection: true
    },
  ]
}