import React, { Component } from 'react';
import styles from './WaitForIt.module.scss';

class WaitForIt extends Component {

  render() {
    return (
        <div className={styles.spinner}>
            <div className={styles.bounce1}></div>
            <div className={styles.bounce2}></div>
            <div className={styles.bounce3}></div>
        </div>
    );
  }
}

export default WaitForIt; 

