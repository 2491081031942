import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import GridinQuestion from './GridinQuestion';
import styles from './TestSectionAnswers.module.scss';


class TestSectionAnswers extends React.Component {

  render() {
    const { questionCount, answerChoices, answers, gridinQuestions, testType } = this.props;
    const columnCount = 2;
    const columns = [];
    const rowMax = Math.round(questionCount / 2);

    for (var x = 0; x < columnCount; x++) {
      var questions = [];
      for(var y = 1; y <= rowMax; y++) {
        
        var questionNum = x*rowMax + y;
        var letters =  (questionNum % 2) === 0 ? answerChoices[1] : answerChoices[0];
        var answerSelected = answers[String(questionNum)] ? answers[String(questionNum)]: '';
       
        if (questionNum <= questionCount){
          if(gridinQuestions && (questionNum >= gridinQuestions[0] && questionNum <= gridinQuestions[1])) {
            questions.push( <GridinQuestion 
              chooseAnswer={this.props.chooseAnswer}
              key={questionNum.toString()}
              questionNumber={questionNum.toString()}
              answerOptions={letters}
              answerSelected={answerSelected}
              isShadedRow={(y % 2) === 1}>
            </GridinQuestion>);
          }
          else {
            questions.push( <Question 
              testType={testType}
              chooseAnswer={this.props.chooseAnswer}
              key={questionNum.toString()}
              questionNumber={questionNum.toString()}
              answerOptions={letters}
              answerSelected={answerSelected}
              isShadedRow={(y % 2) === 1}>
            </Question>);
          }
        }
      }
      var column = (<div key={x.toString()} className={styles.questionColumn}>{questions}</div>);
      columns.push(column);
    }
    return (
      <div className={styles.testSectionAnswers}>
        <div className={styles.title}>Your Answers</div>
        <div className={styles.columns}>{columns}</div>
      </div>);
  }
}

TestSectionAnswers.propTypes = {
  questionCount: PropTypes.number.isRequired,
  answerChoices: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object.isRequired,
  gridinQuestions: PropTypes.array,
  testType: PropTypes.string,
};

TestSectionAnswers.defaultProps = {
  questionCount: 75,
  answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
  answers: {}
};

export default TestSectionAnswers;
