import React from 'react';
import WaitForIt from '../common/WaitForIt';

import styles from './TestSessionSubmit.module.scss';

class TestSessionSubmit extends React.Component {

    render() {
        return (
            <div className={styles.testSessionSubmit}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <span className={styles.header}>Scoring your test</span>
                        <WaitForIt/>
                        <span className={styles.subHeader}>please wait</span>
                    </div>   
                </div>  
            </div>
        );
    }
}

export default TestSessionSubmit;