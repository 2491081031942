import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../Button';
import WaitForIt from '../common/WaitForIt';
import ErrorMessage from '../common/ErrorMessage';
import { completeTestSectionPostError } from '../../actions';

import styles from './SubmittingAnswers.module.scss';

class SubmittingAnswers extends React.Component {
  
  componentDidUpdate() {
    if(!this.props.sectionSubmitting && !this.props.sectionError) {
      this.props.history.replace('/test');
    }
  }

  continueTest() {
    this.props.completeTestSectionPostError();
  }
  
  render() {
    if(this.props.sectionSubmitting) {
      return (
        <div className={styles.submit}>
          <div className={styles.content}>
            <div className={styles.header}>
              <span className={styles.header}>Submitting your section answers</span>
              <WaitForIt/>
              <span className={styles.subHeader}>please wait</span>
            </div>   
          </div>  
        </div>
      );
    }
    else if(this.props.sectionError) {
      const testType = this.props.test.testType;
      return (
        <div className={styles.submit}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.header}>There was a problem submitting your section</div>
              <div className={styles.subHeader}>Your answers have been saved, please continue the test.</div>
              <div className={styles.text}>When you finish the test you we will re-submit this section of the test for you. If that fails you will need to <a target="_blank" href={"https://www.testive.com/" + testType + "-proctored-test/"}>contact support</a> in order to complete the exam.</div>
              <ErrorMessage errors={this.props.sectionError}/>
                <Button
                  onClick={this.continueTest.bind(this)}>
                  Continue Test
                </Button>
            </div>   
          </div>  
        </div>
      );
    }
    else {
      return null;
    }
  }
}
  
SubmittingAnswers.propTypes = {
  sectionSubmitting: PropTypes.bool,
  sectionError: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    name: state.user.displayName,
    email: state.user.email,
    test: state.testsession.test,
    sectionSubmitting: state.testsession.sectionSubmitting,
    sectionError: state.testsession.sectionError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    completeTestSectionPostError: () => dispatch(completeTestSectionPostError())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmittingAnswers);