import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import { userLogin } from './../../actions';
import Link from './../common/Link';
import ErrorMessage from './../common/ErrorMessage';
import Images from '../../assets/Images';
import Button from '../Button';


class Login extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      email: props.email,
      password: '',
    };
    
    this.onLogin = this.onLogin.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
  }
   
  onLogin(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.login({
      email: this.state.email,
      password: this.state.password
    });
  }
  
  onForgotPassword() {
    this.props.history.push('/resetpassword');
  }
  
  onCreateAccount() {
    this.props.history.push('/register');
  }
  
  componentDidUpdate() {
    if(this.props.isAuthenticated) {
      this.props.history.replace('/choosetest');
    }
  }
  
  render() {
    const { loginError } = this.props;

    return (
      <div className={styles.login}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>online practice test</div>
          <form className={styles.formContainer} onSubmit={this.onLogin}>
            <input 
              name='email'
              type='email' 
              autoComplete='email'
              placeholder='email'
              maxLength='75'
              required
              onChange={(e) => this.setState({email: e.target.value})}
              value={this.state.email}>
            </input>
            <input 
              name='password'
              type='password' 
              autoComplete='current-password'
              placeholder='password'
              maxLength='128'
              required
              onChange={(e) => this.setState({password: e.target.value})}>
            </input>
            <ErrorMessage errors={loginError}/>  
         
            <div className={styles.buttonContainer}>
              <input type='submit' value='LOGIN'></input>
              <Button 
                ctaHighlight={false}
                onClick={this.onCreateAccount}>
                Sign Up
              </Button>
            </div>
          </form>
          <Link 
            onPress={this.onForgotPassword}
            text="forgot password?">
          </Link>      
        </div>

      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  history: PropTypes.object,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  loginError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

function mapStateToProps(state) {
  const { isAuthenticating, isAuthenticated } = state.user;
  
  return {
    isAuthenticating: isAuthenticating,
    isAuthenticated: isAuthenticated,
    loginError: state.errors.loginError,
    email: state.user.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: request => dispatch(userLogin(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);