import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../Button';
import PoweredByTestive from '../common/PoweredByTestive';
import { ACT_1, ACT_2, ACT_3, ACT_4, ACT_5, ACT_6, ACT_7, ACT_8, SAT_1, SAT_2, SAT_3, SAT_4, SAT_5, SAT_6, SAT_7, SAT_8, testMapper } from '../../constants/tests';

import { chooseTest, clearProps } from '../../actions';
import styles from './ChooseTest.module.scss';
import Images from '../../assets/Images';

class ChooseTest extends React.Component {
  constructor(props) {
    super(props);

    this.chooseSAT = this.chooseSAT.bind(this);
    this.chooseACT = this.chooseACT.bind(this);
    this.chooseSaved = this.chooseSaved.bind(this);
    this.chooseURL = this.chooseURL.bind(this);

  }

  chooseSAT(index) {
    if(index === 1) {
      this.props.chooseTest(SAT_1);
    }
    if(index === 2) {
      this.props.chooseTest(SAT_2);
    }
    if(index === 3) {
      this.props.chooseTest(SAT_3);
    }
    if(index === 4) {
      this.props.chooseTest(SAT_4);
    }
    if(index === 5) {
      this.props.chooseTest(SAT_5);
    }
    if(index === 6) {
      this.props.chooseTest(SAT_6);
    }
    if(index === 7) {
      this.props.chooseTest(SAT_7);
    }
    if(index === 8) {
      this.props.chooseTest(SAT_8);
    }
    this.props.history.push('/welcome');
  }

  chooseACT(index) {
    if(index === 1) {
      this.props.chooseTest(ACT_1);
    }
    if(index === 2) {
      this.props.chooseTest(ACT_2);
    }
    if(index === 3) {
      this.props.chooseTest(ACT_3);
    }
    if(index === 4) {
      this.props.chooseTest(ACT_4);
    }
    if(index === 5) {
      this.props.chooseTest(ACT_5);
    }
    if(index === 6) {
      this.props.chooseTest(ACT_6);
    }
    if(index === 7) {
      this.props.chooseTest(ACT_7);
    }
    if(index === 8) {
      this.props.chooseTest(ACT_8);
    }
    this.props.history.push('/welcome');
  }

  chooseURL() {
    this.props.chooseTest(testMapper(this.props.urlProps.test, this.props.urlProps.id));
    this.props.history.push('/welcome');
  }

  chooseSaved() {
    this.props.clearProps();
    this.props.history.push('/welcome');
  }

  componentDidMount() {
    let props = this.props.urlProps;
    // passed test params & no previous test selected
    if(Object.keys(props).length > 0 && !this.props.test)  {
      this.props.chooseTest(testMapper(props.test, props.id));
      this.props.history.push('/welcome');
    }
    // pass test params & selected test are the same
    if(Object.keys(props).length > 0 && this.props.test) {
      let urlTest = testMapper(props.test, props.id);
      if(urlTest.testName === this.props.test.testName) {
        this.props.history.push('/welcome');
      }
    }
  }

  render() {
    // if we are passed test params and there is a saved test, confirm which the user wants to use
    if(Object.keys(this.props.urlProps).length > 0 && this.props.test) {
      let urlTest = testMapper(this.props.urlProps.test, this.props.urlProps.id);
      let savedTest = this.props.test;
      return (
        <div className={styles.chooseTest}>
          <div className={styles.topContainer}>
            <div className={styles.title}>Welcome to the Online Practice Test Portal</div>   
          
            <div className={styles.logoContainer}>
              <img src={Images.logoPoweredBy} height={50} alt='testive'></img>
            </div>
            <div className={styles.subtitle}>You clicked a link to take you directly to the {urlTest.testName} but we have previously saved a session for the {savedTest.testName}. Which test would you like to complete?</div>
            <div className={styles.choiceBox}>
              <div className={styles.testHeader}>Select your test</div>
              <div className={styles.buttonContainerSmall}>
                <Button
                  onClick={this.chooseSaved}>
                  {savedTest.testName}
                </Button>
                <Button
                  ctaHighlight={false}
                  onClick={this.chooseURL}>
                  {urlTest.testName} 
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <PoweredByTestive history={this.props.history}/>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.chooseTest}>
          <div className={styles.topContainer}>
            <div className={styles.title}>Welcome to the Online Practice Test Portal</div>   
          
            <div className={styles.logoContainer}>
              <img src={Images.logoPoweredBy} height={50} alt='testive'></img>
              {/* <img src={Images.logoSlyAndText} height={80} alt='testive'></img> */}
            </div>
            {/* <div className={styles.subtitle}>We currently offer an online SAT and an online ACT, please choose which you would like to take below.</div> */}
            <div className={styles.choiceBox}>
              <div className={styles.testHeader}>Select your test</div>
              <div className={styles.buttonContainer}>
                <div className={styles.testContainer}>
                  SAT
                  <Button
                    onClick={() => this.chooseSAT(5)}>
                    SAT Test 5
                  </Button>
                  <Button
                    onClick={() => this.chooseSAT(6)}>
                    SAT Test 6
                  </Button>
                  <Button
                    onClick={() => this.chooseSAT(7)}>
                    SAT Test 7
                  </Button>
                  <Button
                    onClick={() => this.chooseSAT(8)}>
                    SAT Test 8
                  </Button>
                </div>
                <div className={styles.testContainer}>
                  ACT
                  <Button
                    onClick={() => this.chooseACT(6)}>
                    ACT Prep Guide Test 1
                  </Button>
                  <Button
                    onClick={() => this.chooseACT(7)}>
                    ACT Prep Guide Test 2
                  </Button>
                  <Button
                    onClick={() => this.chooseACT(8)}>
                    ACT Prep Guide Test 3
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <PoweredByTestive history={this.props.history}/>
          </div>
        </div>
      );
    }
  }
}

ChooseTest.propTypes = {
  test: PropTypes.object,
  history: PropTypes.object.isRequired,
  chooseTest: PropTypes.func.isRequired,
  clearProps: PropTypes.func.isRequired,
  urlProps: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    test: state.testsession.test,
    urlProps: state.props
  };
}

function mapDispatchToProps(dispatch) {
  return {
    chooseTest: test => dispatch(chooseTest(test)),
    clearProps: () => dispatch(clearProps()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTest);