import * as constants from '../constants/actions';

export default function props(state = {}, action) {
  switch (action.type) {
    case constants.SAVE_PROPS:
      return Object.assign({}, state, action.props);
    case constants.CLEAR_PROPS:
      return {};
    default:
      return state;
  }
}