import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Link.module.scss';

class Link extends Component {

  render() {
    return (
      <div className={styles.link} 
        onClick={this.props.onPress.bind(this)}>
        {this.props.text}
      </div>  
    );
  }
}


Link.propTypes = {
  onPress: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default Link; 