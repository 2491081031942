import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VerifyUser.module.scss';
import { verifyUser, chooseTest } from '../../actions';
import { testMapper, testNameMapper } from '../../constants/tests';
import ErrorMessage from '../common/ErrorMessage';
import Button from '../Button';

class InstantVerify extends React.Component {
  
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidUpdate()  {
    const { isValidUserID, test } = this.props;
    if (isValidUserID) {
      if(test) {
        this.props.history.replace('/welcome');
      }
      else {
        this.props.history.replace('/choosetest');
      }
    }
  }

  componentDidMount() {
    const { isAuthenticated, verifyUser, test, chooseTest, userUUID } = this.props;
    console.log(this.props.match.params);
    if (isAuthenticated){
      let id = this.props.match.params.id;
      // User is already logged in push to confirm before login
      if(Object.keys(this.props.match.params).length > 0 && userUUID !== id) {
        // Support test type + index e.g. SAT/1
        if(this.props.match.params.test || this.props.match.params.testId) {
          let urlString = '/replace/' + id;
          if(this.props.match.params.test) {
            urlString += '/' + this.props.match.params.test;
          }
          if(this.props.match.params.testId) {
            urlString += '/' + this.props.match.params.testId;
          }
          this.props.history.replace(urlString);
        }
        // Pass test name directly  e.g. PrepGuideTest1
        else if(this.props.match.params.testName) {
          let urlString = '/replace/' + id;
          urlString += '/' + this.props.match.params.testName;
          this.props.history.replace(urlString);
        }
        else {
          this.props.history.replace('/replace/' + id);
        }
      }
      // if logged in and passed test, replace existing test
      else if(this.props.match.params.testId) {
        chooseTest(testMapper(this.props.match.params.test, this.props.match.params.testId));
        this.props.history.replace('/welcome');
      }
      else if(this.props.match.params.testName) {
        chooseTest(testNameMapper(this.props.match.params.testName));
        this.props.history.replace('/welcome');
      }
      else if(test) {
        this.props.history.replace('/welcome');
      }
      else {
        this.props.history.replace('/choosetest');
      }
    }
    else {
      const { id, test, testId, testName } = this.props.match.params;
      verifyUser({ user_uuid: id });
      if(test && testId) {
        chooseTest(testMapper(test, testId));
      }
      else if(testName){
        chooseTest(testNameMapper(testName));
      }
    }
  }

  login() {
    this.props.history.replace('/login');
  }

  resetPassword() {
    this.props.history.replace('/resetpassword');
  }
  
  render(){
    const { isValidatingUserID, isValidUserID, error} = this.props;
    if (isValidatingUserID)
      return (<div className={styles.verifyUser}>authenticating...</div>);
    else if (!isValidUserID)
      return ( 
        <div className={styles.verifyUser}>
          <div className={styles.content}>
            <ErrorMessage errors={error}></ErrorMessage>
            {/* <div className={styles.info}>If you know your user name and password</div> */}
            <Button onClick={this.login}>
              go to login
            </Button>
            {/* <div className={styles.info}>If you need a new password</div> */}
            <Button onClick={this.resetPassword}>
              I need to reset my password
            </Button>
            <div className={styles.info}>If you need help email</div>
            <a href={"mailto:happy@testive.com"}>happy@testive.com</a>
          </div>
        </div>);
    else return null;
  }

}

InstantVerify.propTypes = {
  verifyUser: PropTypes.func.isRequired,
  chooseTest: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userUUID: PropTypes.string,
  test: PropTypes.object,
  history: PropTypes.object.isRequired,
  isValidatingUserID: PropTypes.bool.isRequired,
  isValidUserID: PropTypes.bool.isRequired,
  completedOnboarding: PropTypes.bool.isRequired,
  error: PropTypes.string
};

function mapStateToProps(state) {
  return {
    userUUID: state.user.userUUID,
    isValidatingUserID: state.user.isValidatingUserID,
    isValidUserID: state.user.isValidUserID,
    error: state.user.userVerifyError,
    completedOnboarding: state.user.completedOnboarding,
    isAuthenticated: state.user.isAuthenticated,
    test: state.testsession.test,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUser: request => dispatch(verifyUser(request)),
    chooseTest: test => dispatch(chooseTest(test))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantVerify);