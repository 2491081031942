import fetch from 'isomorphic-fetch';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGIN_LINK_REQUEST, LOGIN_LINK_SUCCESS, LOGIN_LINK_FAILURE, LOGOUT } from '../constants/actions';

function userLoginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

function userLoginSuccess(json, email) {
  return {
    type: LOGIN_SUCCESS,
    data: json.data,
    receivedAt: Date.now(),
    email: email,
  };
}

function userLoginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function userLogin(form) {
  return function (dispatch) {
    dispatch(userLoginRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];

    for (var key in form) {
      if (form.hasOwnProperty(key)) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(form[key]));
      }
    }

    return fetch('https://app.testive.com/api/a/student_app_login/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(userLoginFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(userLoginSuccess(json, form.email));
          } else if(json.status === 'error'){
            dispatch(userLoginFailure(json.msg));
          }
        }
      );
  };
}

function sendLoginLinkRequest() {
  return {
    type: LOGIN_LINK_REQUEST
  };
}

function sendLoginLinkSuccess(json, email) {
  return {
    type: LOGIN_LINK_SUCCESS,
    data: json.data,
    receivedAt: Date.now(),
    email: email,
  };
}

function sendLoginLinkFailure(error) {
  return {
    type: LOGIN_LINK_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function sendLoginLink(form) {
  return function (dispatch) {
    dispatch(sendLoginLinkRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];

    for (var key in form) {
      if (form.hasOwnProperty(key)) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(form[key]));
      }
    }

    return fetch('https://app.testive.com/api/a/student_app_login_link/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(sendLoginLinkFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(sendLoginLinkSuccess(json, form.email));
          } else if(json.status === 'error'){
            dispatch(sendLoginLinkFailure(json.msg));
          }
        }
      );
  };
}

export function userLogout() {
  return {
    type: LOGOUT
  };
}
