import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './TestSessionPause.module.scss';
import TestOutline from '../TestOutline';
import Button from '../Button';

class TestSessionPause extends React.Component {
  
  constructor(props) {
    super(props);
    this.resumeTest = this.resumeTest.bind(this);
  }

  resumeTest() {
    this.props.history.push('/test');
  }

  render() {
    const { test, extendedTime, currentStep, currentTimer, doubleTime } = this.props;

    const testTimeMinutes = Math.floor(currentTimer/60);
    const testTimeSeconds = currentTimer % 60;


    return (
      <div className={styles.testSessionPause}>
        <div className={styles.content}>
          <div className={styles.title}>your test session is paused</div>
          <div className={styles.subTitle}>test section:<span className={styles.testSectionTitle}>{currentStep.subject}</span></div>
          <div className={styles.subTitleTime}>
            paused at:
            <span className={styles.timeNumber}>
              {testTimeMinutes} minutes and {testTimeSeconds} seconds 
            </span>
            </div>
          <div className={styles.info}></div>
          <TestOutline
            testInfo={test}
            currentTimer={currentTimer}
            extendedTime={extendedTime}
            doubleTime={doubleTime}
            currentStepIndex={currentStep.stepIndex}
          ></TestOutline>
          <div className={styles.buttonContainer}>
            <Button
              onClick={this.resumeTest}
              ctaHighlight={false}>
              RESUME TEST
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
    
TestSessionPause.propTypes = {
  test: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  extendedTime: PropTypes.bool.isRequired,
  doubleTime: PropTypes.bool.isRequired,
  currentStep: PropTypes.object.isRequired,
  currentTimer: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    test: state.testsession.test,
    extendedTime: state.testsession.extendedTime,
    doubleTime: state.testsession.doubleTime,
    currentStep: state.testsession.currentStep,
    currentTimer: state.testsession.timeSaved,
  };
}
    
export default connect(mapStateToProps)(TestSessionPause);