import React from 'react';
import PropTypes from 'prop-types';
import styles from './GridinQuestion.module.scss';
import cx from 'classnames';

class GridinQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: props.answerSelected ? props.answerSelected : ''
    }
    
    this.answerInput = this.answerInput.bind(this);
    this.chooseAnswer = this.chooseAnswer.bind(this);
  }

  answerInput(event) {
    let value = event.target.value;
    if (/^[0-9./]{0,4}$/.test(value)) {
      if(value.length > 0) {
        this.setState({
          answer: value
        });
      }
      else {
        this.setState({
          answer: ''
        });
      }
    }
    else {
      value = value.substring(0, 4);
      event.target.value = value.replace(/[^0-9./]+/gm, '');
    }
  }

  chooseAnswer() {
    this.props.chooseAnswer(this.props.questionNumber, this.state.answer);
  }

  
  render() {
    const { questionNumber, isShadedRow, disabled } = this.props;
    
    let questionStyles = (isShadedRow) 
      ? cx(styles.questionContainer, styles.shadedRow)
      : styles.questionContainer;
    
    questionStyles = (disabled)
      ? cx(questionStyles, styles.disabled) 
      : questionStyles;
      
    return (
      <div className={questionStyles}>
        <div className={styles.question}>
          <div className={styles.questionNumber}>{questionNumber}</div>
          <div className={styles.answerInput}><input type='text' onBlur={this.chooseAnswer} onChange={this.answerInput} value={this.state.answer} /></div>
        </div>
      </div>
    );
  }
}
    
GridinQuestion.propTypes = {
  questionNumber: PropTypes.string.isRequired,
  isShadedRow: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  answerSelected: PropTypes.string,
  chooseAnswer: PropTypes.func.isRequired,
};
    
GridinQuestion.defaultProps = {
  questionNumber: '1',
  answerOptions: ['A', 'B', 'C', 'D'],
  isShadedRow: false,
  disabled: false
};
    
export default GridinQuestion;