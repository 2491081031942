import * as constants from '../constants/actions';

let savedTest = JSON.parse(localStorage.getItem('savedTest'));
let testAnswers = JSON.parse(localStorage.getItem('testAnswers'));
let answerTimes = JSON.parse(localStorage.getItem('answerTimes'));
let currentStepIndex = parseInt(localStorage.getItem('currentStepIndex'));
currentStepIndex = currentStepIndex ? currentStepIndex : 0;
let currentStep = savedTest ? savedTest.steps[currentStepIndex] : undefined;
let timeSaved = parseInt(localStorage.getItem('timeSaved'));
let pageSaved = parseInt(localStorage.getItem('pageSaved'));
let results = JSON.parse(localStorage.getItem('testResults'));
let extendedTime = JSON.parse(localStorage.getItem('extendedTime'));
let doubleTime = JSON.parse(localStorage.getItem('doubleTime'));
let showInstructionModal = JSON.parse(localStorage.getItem('showInstructionModal'));
let hideScoreWelcome = JSON.parse(localStorage.getItem('hideScoreWelcome'));
// let timerStart = Date.parse(parseInt(localStorage.getItem('timerStart')));
let timerStart = localStorage.getItem('timerStart');
timerStart = timerStart ? new Date(timerStart) : undefined;
let testStartDateStamp = localStorage.getItem('testStartDateStamp');
testStartDateStamp = testStartDateStamp ? new Date(testStartDateStamp) : undefined;
let failedSubmission = JSON.parse(localStorage.getItem('failedSubmission'));

let testSessionStatus = results ? 'COMPLETE' 
  : testStartDateStamp ? 'ACTIVE': 'NOTSTARTED';

const initialState = {
  test: savedTest ? savedTest : undefined,
  testSessionStatus: testSessionStatus,
  currentStepIndex: currentStepIndex,
  currentStep: currentStep,
  testStartDateStamp: testStartDateStamp,
  extendedTime: extendedTime ? extendedTime : false,
  doubleTime: doubleTime ? doubleTime : false,
  showInstructionModal: showInstructionModal != null ? showInstructionModal : true,
  showScoreWelcome: hideScoreWelcome ? false : true,
  results: results ? results : {},
  resultsLoading: false,
  sectionSubmitting: false,
  timerStart: timerStart,
  timeSaved: timeSaved,
  pageSaved: pageSaved,
  testAnswers: testAnswers ? testAnswers : {}, // format {ACT_1_MATH: {1: 'A', 2: 'F', 3: 'None', ...}, ACT_1_READING: {...}, ...}
  answerTimes: answerTimes ? answerTimes : {}, // format {ACT_1_MATH: {1: 'A', 2: 'F', 3: 'None', ...}, ACT_1_READING: {...}, ...}
  failedSubmission: failedSubmission ? failedSubmission : false,
  error: undefined,
};
export default function testsession(state = initialState, action) {
  switch (action.type) {
      
    case constants.LOGOUT: 
      return Object.assign({}, state, {
        test: undefined,
        currentStepIndex: 0,
        currentStep: undefined,
        results: undefined,
        resultsLoading: false,
        extendedTime: false,
        doubleTime: false,
        timeSaved: undefined,
        pageSaved: undefined,
        timerStart: undefined,
        failedSubmission: false,
        testAnswers: {},
        answerTimes: {},
        testSessionStatus: 'NOTSTARTED',
        testStartDateStamp: undefined,
        error: undefined
      });

    case constants.SET_EXTENDED_TIME:
      if(action.value) {
        localStorage.setItem('extendedTime', action.value);
        localStorage.removeItem('doubleTime');
        return Object.assign({}, state, {
          extendedTime: action.value,
          doubleTime: false,
        });
      }
      else {
        localStorage.setItem('extendedTime', action.value);
        return Object.assign({}, state, {
          extendedTime: action.value
        });
      }

    case constants.SET_DOUBLE_TIME:
      if(action.value) {
        localStorage.setItem('doubleTime', action.value);
        localStorage.removeItem('extendedTime');
        return Object.assign({}, state, {
          doubleTime: action.value,
          extendedTime: false,
        });
      }
      else {
        localStorage.setItem('doubleTime', action.value);
        return Object.assign({}, state, {
          doubleTime: action.value
        });
      }

    case constants.HIDE_SCORE_WELCOME:
      localStorage.setItem('hideScoreWelcome', true);
      return Object.assign({}, state, {
        showScoreWelcome: false
      });

    case constants.TOGGLE_INSTRUCTION_MODAL:
      var show = !state.showInstructionModal;
      localStorage.setItem('showInstructionModal', show);
      return Object.assign({}, state, {
        showInstructionModal: show
      });

    case constants.CHOOSE_TEST:
      if(!state.test || action.test.testName !== state.test.testName) {
        localStorage.setItem('savedTest', JSON.stringify(action.test));
        localStorage.removeItem('currentStepIndex');
        localStorage.removeItem('testAnswers');
        localStorage.removeItem('answerTimes');
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.removeItem('extendedTime');
        localStorage.removeItem('testStartDateStamp');
        return Object.assign({}, state, {
          test: action.test,
          testAnswers: {},
          answerTimes: {},
          currentStepIndex: 0,
          currentStep: action.test.steps[0],
          resultsLoading: false,
          timeSaved: undefined,
          pageSaved: undefined,
          extendedTime: false,
          testStartDateStamp: undefined,
        });
      }
      else {
        return state;
      }

    case constants.CHANGE_TEST:
      localStorage.removeItem('savedTest');
      localStorage.removeItem('currentStepIndex');
      localStorage.removeItem('testAnswers');
      localStorage.removeItem('answerTimes');
      localStorage.removeItem('timeSaved');
      localStorage.removeItem('pageSaved');
      localStorage.removeItem('extendedTime');
      localStorage.removeItem('testStartDateStamp');
      return Object.assign({}, state, {
        test: {},
        testAnswers: {},
        answerTimes: {},
        currentStepIndex: 0,
        currentStep: {},
        resultsLoading: false,
        timeSaved: undefined,
        pageSaved: undefined,
        extendedTime: false,
        testStartDateStamp: undefined,
      });

    case constants.START_SESSION_SUCCESS:
      var dateStampNow = new Date();
      localStorage.setItem('testStartDateStamp', dateStampNow);
      
      return Object.assign({}, state, {
        test: action.test,
        currentStepIndex: 0,
        currentStep: action.test.steps[0],
        testSessionStatus: 'ACTIVE',
        testStartDateStamp: dateStampNow,
        showInstructionModal: true,
      });

    case constants.PAUSE_SESSION:
      localStorage.setItem('timeSaved', action.timeSaved);
      localStorage.setItem('pageSaved', action.pageSaved);
      
      return Object.assign({}, state, {
        testSessionStatus: 'PAUSED',
        timeSaved: action.timeSaved,
        pageSaved: action.pageSaved
      });

    case constants.UPDATE_SESSION_STATE:
      localStorage.setItem('timeSaved', action.time);
      localStorage.setItem('pageSaved', action.pageNumber);
      return Object.assign({}, state, {
        timeSaved: action.time,
        pageSaved: action.pageNumber
      });

    case constants.RESUME_SESSION:
      // this should never happen but it did so safety first
      if (state.currentStepIndex >= state.test.steps.length)
      {
        localStorage.setItem('currentStepIndex', 0);
        return Object.assign({}, state, {
          testSessionStatus: 'COMPLETED',
          currentStepIndex: 0,
          currentStep: state.test.steps[0],
        });
      }
      else {
        return Object.assign({}, state, {
          testSessionStatus: 'ACTIVE',
        });
      }
      
    case constants.POST_ANSWERS_REQUEST:
      return Object.assign({}, state, {
        resultsLoading: true,
        testSessionStatus: 'COMPLETED',
        error: undefined,
      });

    case constants.POST_ANSWERS_FAILURE:
      return Object.assign({}, state, {
        resultsLoading: false,
        testSessionStatus: 'FAILURE',
        error: action.error,
      });

    case constants.SKIP_SECTION:
      if(state.currentStepIndex+1 < state.test.steps.length) {
        var nextStepIndex = state.currentStepIndex + 1
        localStorage.setItem('currentStepIndex', nextStepIndex);
        localStorage.setItem('timerStart', new Date());
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.setItem('showInstructionModal', true);

        return Object.assign({}, state, {
          timerStart: new Date(),
          showInstructionModal: true,
          timeSaved: null,
          pageSaved: null,
          currentStepIndex: nextStepIndex,
          currentStep: state.test.steps[nextStepIndex],
        });
      }
      // skipped last section -- go to results
      else {
        localStorage.removeItem('currentStepIndex');
        localStorage.removeItem('testAnswers');
        localStorage.removeItem('answerTimes');
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.removeItem('extendedTime');
        localStorage.removeItem('doubleTime');
        return Object.assign({}, state, {
          testAnswers: {},
          answerTimes: {},
          currentStepIndex: 0,
          currentStep: state.test.steps[0],
          submittingResults: false,
          resultsLoading: false,
          testSessionStatus: 'COMPLETED'
        });
      }
      
    case constants.COMPLETE_BREAK:
      if(state.currentStepIndex+1 < state.test.steps.length) {
        var nextStepIndex = state.currentStepIndex + 1
        localStorage.setItem('currentStepIndex', nextStepIndex);
        localStorage.setItem('timerStart', new Date());
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.setItem('showInstructionModal', true);

        return Object.assign({}, state, {
          timerStart: new Date(),
          showInstructionModal: true,
          timeSaved: null,
          pageSaved: null,
          currentStepIndex: nextStepIndex,
          currentStep: state.test.steps[nextStepIndex],
        });
      }
      else {
        return Object.assign({}, state, {});
      }
      
    case constants.COMPLETE_SECTION_REQUEST:
      return Object.assign({}, state, {
        sectionSubmitting: true,
        resultsLoading: true,
      });

    case constants.COMPLETE_SECTION_FAILURE:
      localStorage.setItem('failedSubmission', JSON.stringify(true));
      return Object.assign({}, state, {
        sectionSubmitting: false,
        resultsLoading: false,
        failedSubmission: true,
        sectionError: action.error
      });
      
    case constants.COMPLETE_SECTION_SUCCESS:
      // if test section answers were provided save them
      // they will not be provided when we complete a break step
      var allSections = state.testAnswers;
      localStorage.setItem('testAnswers', JSON.stringify(allSections));
    
      // if more steps go to the next one, otherwise leave it be
      if (state.currentStepIndex+1 < state.test.steps.length) {

        var nextStepIndex = state.currentStepIndex + 1
        localStorage.setItem('currentStepIndex', nextStepIndex);
        localStorage.setItem('timerStart', new Date());
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.setItem('showInstructionModal', true);

        return Object.assign({}, state, {
          testAnswers: allSections,
          timerStart: new Date(),
          showInstructionModal: true,
          sectionSubmitting: false,
          timeSaved: null,
          pageSaved: null,
          resultsLoading: false,
          currentStepIndex: nextStepIndex,
          currentStep: state.test.steps[nextStepIndex],
        });
      }
      else if(action.data.is_complete) {
        localStorage.removeItem('currentStepIndex');
        localStorage.removeItem('testAnswers');
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.removeItem('extendedTime');
        localStorage.removeItem('doubleTime');
        return Object.assign({}, state, {
          testAnswers: {},
          currentStepIndex: 0,
          currentStep: state.test.steps[0],
          submittingResults: false,
          resultsLoading: false,
          testSessionStatus: 'COMPLETED'
        });
      }
      else {
        return Object.assign({}, state, {
          testAnswers: allSections,
        });
      }

    case constants.COMPLETE_SECTION_POST_ERROR:
      //THIS IS IDENTICAL TO THE ABOVE CASE, THIS OCCURS AFTER SECTION SUBMIT ERROR SO USER CAN CONTINUE TEST
      
      // if test section answers were provided save them
      // they will not be provided when we complete a break step
      var allSections = state.testAnswers;
      localStorage.setItem('testAnswers', JSON.stringify(allSections));
    
      // if more steps go to the next one, otherwise leave it be
      if (state.currentStepIndex+1 < state.test.steps.length) {

        var nextStepIndex = state.currentStepIndex + 1
        localStorage.setItem('currentStepIndex', nextStepIndex);
        localStorage.setItem('timerStart', new Date());
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.setItem('showInstructionModal', true);

        return Object.assign({}, state, {
          testAnswers: allSections,
          timerStart: new Date(),
          showInstructionModal: true,
          sectionSubmitting: false,
          sectionError: null,
          timeSaved: null,
          pageSaved: null,
          currentStepIndex: nextStepIndex,
          currentStep: state.test.steps[nextStepIndex],
        });
      }
      else {
        return Object.assign({}, state, {
          testAnswers: allSections,
          sectionError: null,
        });
      }

    case constants.POST_ANSWERS_SUCCESS:
        localStorage.removeItem('currentStepIndex');
        localStorage.removeItem('testAnswers');
        localStorage.removeItem('timeSaved');
        localStorage.removeItem('pageSaved');
        localStorage.removeItem('extendedTime');
        localStorage.removeItem('doubleTime');

        return Object.assign({}, state, {
          testAnswers: {},
          currentStepIndex: 0,
          currentStep: state.test.steps[0],
          submittingResults: false,
          resultsLoading: false,
          testSessionStatus: 'COMPLETED'
        });
      
    case constants.CHOOSE_ANSWER:
      var testAnswers = state.testAnswers;
    
      if (!testAnswers[action.testSectionId]) {
        testAnswers[action.testSectionId] = {};
      }
     
      testAnswers[action.testSectionId][String(action.question)] = action.answer;

      var answerTimes = state.answerTimes;
      if (!answerTimes[action.testSectionId]) {
        answerTimes[action.testSectionId] = {};
      }

      if(answerTimes[action.testSectionId[String(action.question)]]) {
        answerTimes[action.testSectionId][String(action.question)] += state.timeSaved - action.timeSaved;
      }
      else if(!state.timeSaved) {
        let baseTime = state.currentStep.time;
        if(state.extendedTime) {
          baseTime = state.currentStep.extendedTime;
        }
        if(state.doubleTime) {
          baseTime = state.currentStep.doubleTime;
        }
        answerTimes[action.testSectionId][String(action.question)] = baseTime - action.timeSaved;
      }
      else {
        answerTimes[action.testSectionId][String(action.question)] = state.timeSaved - action.timeSaved;
      }

      localStorage.setItem('testAnswers', JSON.stringify(testAnswers));
      localStorage.setItem('answerTimes', JSON.stringify(answerTimes));
      localStorage.setItem('timeSaved', action.timeSaved);
      return Object.assign({}, state, {
        testAnswers: testAnswers,
        timeSaved: action.timeSaved,
        resultsLoading: false,
        error: undefined,
      });
    default:
      return state;
  }
}