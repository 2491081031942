import * as constants from '../constants/actions';

const initialState = {
  loginError: ''
};

export default function errors(state = initialState, action) {
  switch (action.type) {
    case constants.LOGIN_FAILURE: 
      return Object.assign({}, state, {
        loginError: action.error
      });
    case constants.LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginError: action.error
      });
    default:
      return {};
  }
}