import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './StudentOnboarding.module.scss';
import { studentOnboard } from '../../actions';
import ErrorMessage from '../common/ErrorMessage';
import Images from '../../assets/Images';


class StudentOnboarding extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      password: '',
      confirmPassword: ''
    };

    this.goTologin = this.login.bind(this);
    this.completeOnboarding = this.completeOnboarding.bind(this);
  }

  login() {
    this.props.history.push('/login');
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated)
      this.props.history.replace('/choosetest');
  }

  completeOnboarding(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.studentOnboard({
      user_uuid: this.props.userUUID,
      new_password1: this.state.password,
      new_password2: this.state.confirmPassword
    });
  }
  
  render() {
    const { error, email } = this.props;

    return (
      <div className={styles.studentOnboard}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>Testive online practice test</div>
          <div className={styles.instructions}>Your user name is: {email}</div>
          <div className={styles.instructions}>Set your password to begin your practice test</div>
          <form className={styles.formContainer} onSubmit={this.completeOnboarding}>
            <input 
              name='password'
              type='password' 
              placeholder='password'
              maxLength='128'
              required
              onChange={(e) => this.setState({password: e.target.value})}>
            </input>
            <input 
              name='confirm password'
              type='password' 
              placeholder='confirm password'
              maxLength='128'
              required
              onChange={(e) => this.setState({confirmPassword: e.target.value})}>
            </input>
            <ErrorMessage errors={error}/>  
         
            {/* <div className={styles.buttonContainer}> */}
              <input type='submit' value='set password'></input>
            {/* </div> */}
          </form>
            
        </div>

      </div>
    );
  }
}

StudentOnboarding.propTypes = {
  studentOnboard: PropTypes.func.isRequired,
  history: PropTypes.object,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  userUUID: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticating: state.user.isAuthenticating,
    isAuthenticated: state.user.isAuthenticated,
    email: state.user.email,
    error: state.user.userVerifyError,
    userUUID: state.user.userUUID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    studentOnboard: request => dispatch(studentOnboard(request))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentOnboarding);