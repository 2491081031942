export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE';
export const PAUSE_SESSION = 'PAUSE_SESSION';
export const RESUME_SESSION = 'RESUME_SESSION';
export const COMPLETE_SESSION = 'COMPLETE_SESSION';
export const CHOOSE_ANSWER = 'CHOOSE_ANSWER';
export const NEXT_STEP = 'NEXT_STEP';
export const CHOOSE_TEST = 'CHOOSE_TEST';
export const CHANGE_TEST = 'CHANGE_TEST';
export const COMPLETE_BREAK = 'COMPLETE_BREAK';

export const SET_EXTENDED_TIME = 'SET_EXTENDED_TIME';
export const SET_DOUBLE_TIME = 'SET_DOUBLE_TIME';
export const TOGGLE_INSTRUCTION_MODAL = 'TOGGLE_INSTRUCTION_MODAL';
export const HIDE_SCORE_WELCOME = 'HIDE_SCORE_WELCOME';

export const START_SESSION_REQUEST = 'START_SESSION_REQUEST';
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS';
export const START_SESSION_FAILURE = 'START_SESSION_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const LOGIN_LINK_REQUEST = 'LOGIN_LINK_REQUEST';
export const LOGIN_LINK_SUCCESS = 'LOGIN_LINK_SUCCESS';
export const LOGIN_LINK_FAILURE = 'LOGIN_LINK_FAILURE';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'; 
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';

export const STUDENT_ONBOARD_REQUEST = 'STUDENT_ONBOARD_REQUEST';
export const STUDENT_ONBOARD_SUCCESS = 'STUDENT_ONBOARD_SUCCESS';
export const STUDENT_ONBOARD_FAILURE = 'STUDENT_ONBOARD_FAILURE';

export const FORGOTPASSWORD_REQUEST = 'FORGOTPASSWORD_REQUEST';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const FORGOTPASSWORD_FAILURE = 'FORGOTPASSWORD_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const POST_ANSWERS_REQUEST = 'POST_ANSWERS_REQUEST';
export const POST_ANSWERS_SUCCESS = 'POST_ANSWERS_SUCCESS';
export const POST_ANSWERS_FAILURE = 'POST_ANSWERS_FAILURE';

export const SKIP_SECTION = 'SKIP_SECTION';
export const COMPLETE_SECTION_REQUEST = 'COMPLETE_SECTION_REQUEST';
export const COMPLETE_SECTION_SUCCESS = 'COMPLETE_SECTION_SUCCESS';
export const COMPLETE_SECTION_FAILURE = 'COMPLETE_SECTION_FAILURE';
export const COMPLETE_SECTION_POST_ERROR = 'COMPLETE_SECTION_POST_ERROR';

export const SAVE_PROPS = 'SAVE_PROPS';
export const CLEAR_PROPS = 'CLEAR_PROPS';