import React from 'react';
import PropTypes from 'prop-types';
import styles from './Question.module.scss';
import cx from 'classnames';

class Question extends React.Component {
  constructor(props) {
    super(props);
    
    this.onAnswerClick = this.onAnswerClick.bind(this);
  }

  onAnswerClick(letter) {
    const selectedAnswer = this.props.answerSelected;
    if(letter === selectedAnswer) {
      this.props.chooseAnswer(this.props.questionNumber, 'None');
    }
    else {
      this.props.chooseAnswer(this.props.questionNumber, letter);
    }
  }
  
  render() {
    const { questionNumber, answerOptions, isShadedRow, disabled, answerSelected, testType } = this.props;
    
    const choices = answerOptions.map((letter, index) => 
    {

      const answerStyles = (answerSelected === letter) 
        ? cx(styles.answerLetter, styles.selected)
        : styles.answerLetter;

      const circleStyle = (answerSelected === letter) 
        ? cx(styles.answerCircle, styles.selected)
        : styles.answerCircle;
      
      if (testType === 'ACT') {
        return (
          <span
            className={answerStyles}
            key={letter}
            onClick={() => this.onAnswerClick(letter, index)}
          >{letter}</span>
        );
      }
      else {
        return (
          <div
            className={styles.answerContainer}>
              <span
              className={answerStyles}
              key={letter}
              onClick={() => this.onAnswerClick(letter, index)}
            >{letter}</span>
              <span
              className={circleStyle}
              onClick={() => this.onAnswerClick(letter, index)}
              ></span>
          </div>
        );
      }
    });
      
    let questionStyles = (isShadedRow) 
      ? cx(styles.questionContainer, styles.shadedRow)
      : styles.questionContainer;
    
    questionStyles = (disabled)
      ? cx(questionStyles, styles.disabled) 
      : questionStyles;
      
    return (
      <div className={questionStyles}>
        <div className={styles.question}>
          <div className={styles.questionNumber}>{questionNumber}</div>
          <div className={styles.answerLetters}>{choices}</div>
        </div>
      </div>
    );
  }
}
    
Question.propTypes = {
  questionNumber: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  isShadedRow: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  answerSelected: PropTypes.string,
  chooseAnswer: PropTypes.func.isRequired,
};
    
Question.defaultProps = {
  questionNumber: '1',
  answerOptions: ['A', 'B', 'C', 'D'],
  isShadedRow: false,
  disabled: false
};
    
export default Question;