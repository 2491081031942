import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestOutline.module.scss';
import cx from 'classnames';
import images from '../../assets/Images';

class TestOutline extends React.Component {

  renderStepStatus(stepIndex, currentIndex, currentTimer) {
    const isCompleted = (currentIndex > stepIndex);
    const isActive = (currentIndex === stepIndex);

    if (isCompleted) {
      return (
        <div className={styles.sectionStatus}>
          <img src={images.check} height={20} width={20} alt='completed'></img>
          <span className={styles.statusTextCompleted}>completed</span>
        </div>
      );
    }
    else if (isActive) {

      if (currentTimer) {
        const testTimeMinutes = Math.floor(currentTimer/60);
        const testTimeSeconds = currentTimer % 60;
    
        return (
          <div className={styles.sectionStatus}>
            <img src={images.timer} height={20} width={20} alt='time'></img>
            <div className={styles.statusTextActive}>
              {testTimeMinutes}<span>m</span>{testTimeSeconds}<span>s</span>
            </div>
          </div>
        );
      }
    }
  
    return (null);
  }

  renderTestStep(index, step, currentStepIndex, extendedTime, doubleTime) {

    const { currentTimer } = this.props;
    if (step.type === 'info') {
      // do nothing
      return null;
    }
    else if (step.type === 'test') {     
      var testTime;
      if(extendedTime) {
        testTime = Math.floor(step.extendedTime/60);
      }
      else if(doubleTime) {
        testTime = Math.floor(step.doubleTime/60);
      }
      else {
        testTime = Math.floor(step.time/60);
      }
          
      return (
        <div key={index} className={styles.row}>
          
          <div className={styles.testSection}>
          
            <div className={styles.sectionTitleSubject}>
              <div className={styles.number}>{step.sectionIndex}</div>
              <div>{step.subject}</div>
            </div>
            <span className={styles.sectionTitleDetails}>{testTime} minutes - {step.questionCount} questions</span>
          </div>
          {this.renderStepStatus(index, currentStepIndex, currentTimer)}
        </div>
      );
    }
    else if (step.type === 'break') {
      const breakStyle = (currentStepIndex > index) 
        ? cx(styles.break, styles.completed)
        : styles.break;

      var breakTime;
      if(extendedTime) {
        breakTime = Math.floor(step.extendedTime/60);
      }
      else if(doubleTime) {
        breakTime = Math.floor(step.doubleTime/60);
      }
      else {
        breakTime = Math.floor(step.time/60);
      }
      return (
        <div key={index} className={breakStyle}>{breakTime} minute break</div>
      );
    }
  }

  render() {
    const { testInfo, currentStepIndex, extendedTime, doubleTime } = this.props; 
    var testSteps = [];

    for (var i = 0; i < testInfo.steps.length; i++) {
      let step = this.renderTestStep(i, testInfo.steps[i], currentStepIndex, extendedTime, doubleTime);
      if (step) {
        testSteps.push(step);
      }
    }
    
    var totalTime;
    if(extendedTime) {
      totalTime = testInfo.totalTimeExtended;
    }
    else if(doubleTime) {
      totalTime = testInfo.doubleTimeExtended;
    }
    else {
      totalTime = testInfo.totalTime;
    }
    return (
      <div className={styles.testOutline}>
        <div className={styles.title}>{testInfo.testName}
          <div className={styles.subTitle}>{totalTime}</div>
        </div>
        <div className={styles.testSectionsContainer}>{testSteps}</div>
      </div> 
    );
  }
}

TestOutline.propTypes = {
  testInfo: PropTypes.object.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  extendedTime: PropTypes.bool.isRequired,
  doubleTime: PropTypes.bool.isRequired,
  currentTimer: PropTypes.number.isRequired,
};

TestOutline.defaultProps = {
  currentStepIndex: 0,
  extendedTime: false,
  doubleTime: false,
  currentTimer: 0,
};

export default TestOutline;
