import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import cx from 'classnames';
import Images from '../../assets/Images';
import styles from './PDFViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFViewer extends React.Component {

  constructor(props) {
    super(props);

    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.pageBack = this.pageBack.bind(this);
    this.pageForward = this.pageForward.bind(this);

    this.state = {
      zoom: 1.6,
    };

  }
   
  pageForward() {
    const { pageEnd, pageNumber } = this.props;
    if (pageNumber < pageEnd) {
      this.props.notifyParent(pageNumber+1);
    }
  }
  
  pageBack() {
    const { pageStart, pageNumber } = this.props;
    if (pageNumber > pageStart) {
      this.props.notifyParent(pageNumber-1);
    }
  }

  zoomIn() {
    let newZoom = this.state.zoom + .1;
    this.setState({zoom: newZoom});
  }

  zoomOut() {
    let newZoom = this.state.zoom - .1;
    this.setState({zoom: newZoom});
  }
  
  render() {
    const { zoom } = this.state;
    const { pageStart, pageEnd, pageNumber, test } = this.props;

    const nextStyle = (pageNumber < pageEnd) 
      ? styles.buttonImage 
      : cx(styles.buttonImage, styles.disabled);
    const backStyle = (pageNumber > pageStart) 
      ? styles.buttonImage 
      : cx(styles.buttonImage, styles.disabled);

    const imgSize = 18;
    return (
      <div className={styles.pdfViewer}>
        
        <div className={styles.pdfDocument}>
          <Document
            file={test.testPDF}>
            <Page 
              pageNumber={pageNumber} 
              scale={zoom}
              renderTextLayer={false} //TODO: this causes the horizontal scroll, can we stop that?
            />
          </Document>
        </div>
        <div className={styles.navLinks}>
          <div className={styles.zoom}>
            <img 
              className={styles.buttonImage}
              onClick={this.zoomOut}
              src={Images.zoomOut} 
              height={imgSize} width={imgSize} 
              alt={'zoom out'}/>
            <span>zoom</span>
            <img 
              className={styles.buttonImage}
              onClick={this.zoomIn}
              src={Images.zoomIn} 
              width={imgSize} height={imgSize} 
              alt={'zoom in'}/>
          </div>
          <div className={styles.paging}>
            <img
              className={backStyle}
              onClick={this.pageBack}
              src={Images.back} 
              height={imgSize} width={imgSize} 
              alt={'back'}/>
            <span className={styles.pageNum}>page {pageNumber}</span>
            <img 
              className={nextStyle}
              onClick={this.pageForward}
              src={Images.next} 
              height={imgSize} width={imgSize} 
              alt={'next'}/>
          </div>
        </div>
      </div>
    );
  }
}

PDFViewer.propTypes = {
  test: PropTypes.object.isRequired,
  pageStart: PropTypes.number.isRequired,
  pageEnd: PropTypes.number.isRequired,
  notifyParent: PropTypes.func,
  pageNumber: PropTypes.number.isRequired,
};

PDFViewer.DefaultProps = {
  pageStart: 1,
  pageEnd: 1,
  pageNumber: 1
};

export default PDFViewer;