import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import { startSession } from '../../actions';

import PoweredByTestive from './../common/PoweredByTestive';
import Button from '../Button';

import styles from './LaunchScreen.module.scss';

class LaunchScreen extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.startTest = this.startTest.bind(this);
  }

  startTest() {
    this.props.startTest(this.props.email, this.props.test);
    this.props.history.replace('/test');
  }

  testText(ACTText, SATText) {
    const testType = this.props.test.testType;

    if(testType === 'ACT') {
      return ACTText;
    }
    else if(testType === 'SAT') {
      return SATText;
    }

  }

  render() {
    // const savedTest = Object.keys(this.props.testAnswers).length > 0;
    const nextButton = <span className={styles.ctaCallOut}>START TEST</span>;
    const { test } = this.props;
    return (
      <div className={styles.testInstructions}>
        <div className={styles.topContainer}>
          <div className={styles.instructionSet}>
            <div >Test Directions:</div>
            <div>
              <p>Important: Read this information before selecting the {nextButton} button.
              </p>
              <p>You are about to take the {test.testType}, which is composed of multiple-choice tests in {this.testText('English, Mathematics, Reading, and Science', 'Reading, Writing and Language, Math - No Calculator, and Math - Calculator')}.
              </p>
              {this.testText(<p>These tests measure skills and abilities highly related to high school course work and success in college. <span className={styles.callOut}>Calculators may be used on the mathematics test only.</span>
              </p>,'')}
              {this.testText(<p>For each multiple-choice question, first decide which answer is best. Then, locate the row of ovals on the right side of the screen that is numbered the same as the question. Select the oval in the grid that is lettered the same as your choice.
              </p>, <p>For each multiple-choice question, select the circle in the grid on the right side of the screen that is lettered the same as your choice.</p>)}
              {this.testText(<p>If you change your mind about an answer, choose a different answer and select the oval that corresponds to it.
              </p>, <p>If you would like to change your response, select the circle that corresponds to it.</p>)}
              <p>Press the <span className={styles.callOut}>Right Arrow</span> under the test page to move to the next page, and press the <span className={styles.callOut}>Left Arrow</span> to move back. You may move freely between pages only in the section you are currently working on.
              </p>
              {this.testText(<p>Your score on each test will be based only on the number of questions you answer correctly during the time allowed for that test. You will not be penalized for guessing. <span className={styles.callOut}>It is to your advantage to answer every question.</span>
              </p>, <p>Remember that you receive points for correct answers, but you don’t lose points for incorrect answers. That means you should try to answer every question, even if you’re not sure of the correct answer.</p>)}
              <p>The computer will keep the official time for your examination. There will be a countdown timer that will tell you how much time is remaining for the test you are working on.
              </p>
              <p>If you finish a test early, you select Finish Section. Be careful not to do so unless you are completely done. You will not be able to look back at the questions once a test is complete.
              </p>       
            </div>
          </div>
            
          <div className={styles.buttonContainer}>
            <div className={styles.warning}>WARNING: YOUR TIME WILL BEGIN ONCE YOU SELECT THE {nextButton} button</div>
            <Button 
              onClick={this.startTest}>
              {'START TEST'}
            </Button>
          </div>
        </div>
        <div className={styles.footer}>
          <PoweredByTestive history={this.props.history}/>
        </div>
      </div>
    );
  }
}
  
LaunchScreen.propTypes = {
  test: PropTypes.object.isRequired,
  // testAnswers: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  startTest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
  
function mapStateToProps(state){
  return {
    test: state.testsession.test,
    email: state.user.email,
    // testAnswers: state.testsession.testAnswers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startTest: (email, test) => dispatch(startSession(email, test)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(LaunchScreen);
  