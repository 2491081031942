import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Timer.module.scss';

class Timer extends React.Component {
  constructor(props){
    super(props);
    
    const totalTime = props.timeSaved ? props.timeSaved : props.timeInSeconds;
    this.state = {
      initialTime: totalTime,
      timeInSeconds: totalTime
    };
  
    this.intervalID = window.setInterval(this.count.bind(this), 1000);
  }

  count() {
    let { timeInSeconds } = this.state;
    timeInSeconds -= 1;

    if(timeInSeconds > 0) {
      this.props.notifyParent(timeInSeconds);
      this.setState({
        timeInSeconds: timeInSeconds
      });
    }
    else {
      this.props.onTimerComplete();
    }
  }

  formatNumber(num) {
    //Zero pad numbers
    num = num.toString();
    if(num.length < 2) {
      num = '0' + num;
    }
    return num;
  }

  render() {
    const { timeInSeconds }  = this.state;
    const { 
      timerText, 
      style, 
      firstWarning, 
      secondWarning, 
      finalWarning 
    } = this.props;

    let timerStyle = styles.number;
    if (timeInSeconds <= finalWarning) {
      timerStyle = cx(timerStyle, styles.finalWarning)
    }
    else if (timeInSeconds <= secondWarning) {
      timerStyle = cx(timerStyle, styles.secondWarning)
    }
    else if (timeInSeconds <= firstWarning) {
      timerStyle = cx(timerStyle, styles.firstWarning)
    }
     
    let remainingMinutes = Math.floor(timeInSeconds / 60);
    let remainingSeconds = timeInSeconds % 60;

    return (
      <div className={styles.timer} style={style}>
        <span className={styles.text}>{timerText}</span> 
        <div className={timerStyle}>
          {this.formatNumber(remainingMinutes)}:{this.formatNumber(remainingSeconds)}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalID);
  }
}

Timer.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
  onTimerComplete: PropTypes.func.isRequired,
  firstWarning: PropTypes.number.isRequired,
  secondWarning: PropTypes.number.isRequired,
  finalWarning: PropTypes.number.isRequired,
  notifyParent: PropTypes.func.isRequired,
  currentTime: PropTypes.number,
  timerText: PropTypes.string,
  style: PropTypes.object,
  timeSaved: PropTypes.number,
};

Timer.defaultProps = {
  timeInSeconds:0,
  firstWarning:600,
  secondWarning:300,
  finalWarning:120,
};

export default Timer;
