import { combineReducers } from 'redux';

import testsession from './testsession';
import user from './user';
import errors from './errors';
import props from './props';


const appReducer = combineReducers({
  errors: errors,
  testsession: testsession,
  user: user,
  props: props
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    console.log('root reducer logout');
    // return Object.assign({}, state, {
    //   currentStepIndex: 0,
    //   currentStep: ACT_1.steps[0],
    //   testActive: false,
    //   testComplete: false,
    //   results: {},
    //   resultsLoading: false,
    //   timeSaved: undefined,
    //   pageSaved: undefined,
    //   testAnswers: {} 
    // });
  }
  return appReducer(state, action);
};

// const rootReducer = combineReducers({
//   errors: errors,
//   testsession: testsession,
//   user: user
// });

export default rootReducer;