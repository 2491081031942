import { 
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, 
  REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE,
  USER_VERIFY_REQUEST, USER_VERIFY_SUCCESS, USER_VERIFY_FAILURE,
  STUDENT_ONBOARD_REQUEST, STUDENT_ONBOARD_SUCCESS, STUDENT_ONBOARD_FAILURE,
  LOGIN_LINK_SUCCESS,
} from '../constants/actions';

let userUUID = localStorage.getItem('userUUID');
let loginURL = localStorage.getItem('loginURL');
let email = localStorage.getItem('email');
let displayName = localStorage.getItem('displayName');

const initialState = {
  errors: undefined,
  userVerifyError: undefined,
  isAuthenticating: false,
  isAuthenticated: userUUID ? true : false,
  
  isValidatingUserID: false,
  isValidUserID: false,
  completedOnboarding: false,
  loginLinkSent: false,
 
  userUUID: userUUID ? userUUID : undefined,
  displayName: displayName ? displayName : undefined,
  email: email ? email : '',
  completedTest: false,
  loginURL: loginURL ? loginURL : undefined,
};

const user = (state = initialState, action) => {
  switch(action.type) {

    case STUDENT_ONBOARD_REQUEST: 
      return Object.assign({}, state, {
        isAuthenticating: true,
        isAuthenticated: false,
        userVerifyError: undefined,
        completedOnboarding: false,
      });

      case STUDENT_ONBOARD_SUCCESS: 
        return Object.assign({}, state, {
          isAuthenticating: false,
          isAuthenticated: true,
          userVerifyError: undefined,
          completedOnboarding: true,
        });

    case STUDENT_ONBOARD_FAILURE:
      return Object.assign({}, state, {
        userVerifyError: action.error,
        isAuthenticating: false,
        isAuthenticated: false,
        completedOnboarding: false,
      });

    case USER_VERIFY_REQUEST:
      return Object.assign({}, state, {
        isValidatingUserID: true,
        isValidUserID: false,
        userVerifyError: undefined 
      });

    case USER_VERIFY_SUCCESS:
      localStorage.setItem('userUUID', action.data.user_uuid);
      localStorage.setItem('displayName', action.data.display_name);
      localStorage.setItem('email', action.data.email);
      localStorage.setItem('loginURL', action.data.login_url);
      
      return Object.assign({}, state, {
        isValidatingUserID: false,
        isValidUserID: true,
        userVerifyError: undefined,
        email: action.data.email,
        userUUID: action.data.user_uuid,
        displayName: action.data.display_name,
        completedOnboarding: action.data.completed_onboarding,
        isAuthenticating: false,
        isAuthenticated: true,
        loginURL: action.data.login_url,
      });

    case USER_VERIFY_FAILURE:
      return Object.assign({}, state, {
        isValidatingUserID: false,
        isValidUserID: false,
        userVerifyError: action.error,
      });

    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
        isAuthenticated: false,
        errors: undefined,
      });

    case LOGIN_SUCCESS:
      if (action.data.user_uuid) {
        localStorage.setItem('userUUID', action.data.user_uuid);
      }
      if (action.data.login_url) {
        localStorage.setItem('loginURL', action.data.login_url);
      }
      if (action.data.display_name) {
        localStorage.setItem('displayName', action.data.display_name);
      }
      if (action.email) {
        localStorage.setItem('email', action.email);
      }
      
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        errors: undefined,
        userUUID: action.data.user_uuid,
        displayName: action.data.display_name,
        completedTest: action.data.completed_test,
        loginURL: action.data.login_url,
        email: action.email
      });

    case LOGIN_LINK_SUCCESS:
      return Object.assign({}, state, {
        loginLinkSent: true
      });

    case REGISTER_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
        isAuthenticated: false,
        errors: undefined,
      });

    case REGISTER_SUCCESS: 
      if (action.data.user_uuid) {
        localStorage.setItem('userUUID', action.data.user_uuid);
      }
      if (action.data.login_url) {
        localStorage.setItem('loginURL', action.data.login_url);
      }
      if (action.data.display_name) {
        localStorage.setItem('displayName', action.data.display_name);
      }
      if (action.email) {
        localStorage.setItem('email', action.email);
      }

      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        errors: undefined,
        userUUID: action.data.user_uuid,
        displayName: action.data.display_name,
        loginURL: action.data.login_url,
        email: action.email
      });

    case REGISTER_FAILURE:
      return Object.assign({}, state, {
        errors: action.error,
        isAuthenticating: false,
        isAuthenticated: false,
      });

    case LOGOUT:
      //IAN - this is for bug testing, but might also be a good idea generally?
      localStorage.clear();
   
      return (
        Object.assign({}, state, {
          isAuthenticating: false,
          isAuthenticated: false,
          errors: undefined,
          userUUID: undefined,
          displayName: '',
          email: '',
          loginURL: '',
        })
      );
    default:
      return state;
  }

};

export default user;
