import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ConfirmUser.module.scss';
import { userLogout } from './../../actions';
// import Link from './../common/Link';
import Images from '../../assets/Images';
import Button from '../Button';


class ConfirmUser extends React.Component {
  
  constructor(props) {
    super(props);

    this.userLogout = this.userLogout.bind(this);
    this.confirmedAccount = this.confirmedAccount.bind(this);
  }
   
  userLogout() {
    this.props.userLogout();  
    this.props.history.replace('/login');  
  }
  
  confirmedAccount() {
    if(this.props.test) {
      this.props.history.push('/welcome');
    }
    else {
      this.props.history.push('/choosetest');
    }
  }

  render() {
    const { email, displayName } = this.props;

    return (
      <div className={styles.confirmUser}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>Welcome back</div>
          <div className={styles.displayName}>{displayName}</div>
          <div className={styles.subTitle}>Please confirm your account</div>
          <div className={styles.subTitle}>username: {email}</div>
          <div className={styles.buttonContainer}>
            <Button 
              ctaHighlight={true}
              onClick={this.confirmedAccount}>
              Yes this is my account
            </Button>
            <Button 
              ctaHighlight={false}
              onClick={this.userLogout}>
              No this is not my account
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmUser.propTypes = {
  userLogout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    test: state.testsession.test,
    email: state.user.email,
    displayName: state.user.displayName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: request => dispatch(userLogout(request)), 
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser)