import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import { Prompt } from 'react-router';

import TestSessionBreak from '../TestSessionBreak';
import PracticeTest from '../PracticeTest';
import DetectDesktopOrMobile from '../DetectDesktopOrMobile';

import styles from './TestSession.module.scss';

class TestSession extends React.Component {

  render() {

    const { currentStep } = this.props;
    const PracticeTestResponsive = DetectDesktopOrMobile(PracticeTest);
    return (
      <div className={styles.testSession}>
        {/* {prompt} */}
        {currentStep.type === 'break' 
          ? <TestSessionBreak/>
          : <PracticeTestResponsive
            history={this.props.history}
            currentTestSection={currentStep}/>
        }
      </div>
    );
  }
}

TestSession.propTypes = {
  history: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
};
  
function mapStateToProps(state){
  return {
    currentStep: state.testsession.currentStep
  };
}

export default connect(mapStateToProps)(TestSession);
