import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VerifyUser.module.scss';
import { verifyUser } from '../../actions';
import ErrorMessage from '../common/ErrorMessage';
import Button from '../Button';

class VerifyUser extends React.Component {
  
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidUpdate()  {

    const { isValidUserID, completedOnboarding } = this.props;
    if (isValidUserID) {
        if (!completedOnboarding) {
            this.props.history.replace('/onboarding');
        }
        else {
            this.props.history.replace('/login');
        }
    }
  }

  componentDidMount() {
    const { userUUID } = this.props;
    // if there is a current user logged in then skip this
    // else validate this uuid and send to onboard 
    if (this.props.isAuthenticated){
      let id = this.props.match.params.id;
      if(Object.keys(this.props.match.params).length > 0 && userUUID !== id) {
        let urlString = '/replace/' + id;
        if(this.props.match.params.test) {
          urlString += '/' + this.props.match.params.test;
        }
        if(this.props.match.params.testId) {
          urlString += '/' + this.props.match.params.testId;
        }
        this.props.history.replace(urlString);
      }
      else {
        this.props.history.replace('/');
      }
    }
    else {
      this.props.verifyUser({
          user_uuid: this.props.match.params.id
      });
    }
  }

  login() {
    this.props.history.replace('/login');
  }

  resetPassword() {
    this.props.history.replace('/resetpassword');
  }
  
  render(){
    const { isValidatingUserID, isValidUserID, error} = this.props;
    if (isValidatingUserID)
      return (<div className={styles.verifyUser}>authenticating...</div>);
    else if (!isValidUserID)
      return ( 
        <div className={styles.verifyUser}>
          <div className={styles.content}>
            <ErrorMessage errors={error}></ErrorMessage>
            {/* <div className={styles.info}>If you know your user name and password</div> */}
            <Button onClick={this.login}>
              go to login
            </Button>
            {/* <div className={styles.info}>If you need a new password</div> */}
            <Button onClick={this.resetPassword}>
              I need to reset my password
            </Button>
            <div className={styles.info}>If you need help email</div>
            <a href={"mailto:happy@testive.com"}>happy@testive.com</a>
          </div>
        </div>);
    else return null;
  }

}

VerifyUser.propTypes = {
  verifyUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isValidatingUserID: PropTypes.bool.isRequired,
  isValidUserID: PropTypes.bool.isRequired,
  completedOnboarding: PropTypes.bool.isRequired,
  userUUID: PropTypes.string,
  error: PropTypes.string
};

function mapStateToProps(state) {
  return {
    userUUID: state.user.userUUID,
    isValidatingUserID: state.user.isValidatingUserID,
    isValidUserID: state.user.isValidUserID,
    error: state.user.userVerifyError,
    completedOnboarding: state.user.completedOnboarding,
    isAuthenticated: state.user.isAuthenticated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUser: request => dispatch(verifyUser(request))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);