import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './LoginLink.module.scss';
import { sendLoginLink } from '../../actions';
import Link from '../common/Link';
import ErrorMessage from '../common/ErrorMessage';
import Images from '../../assets/Images';
import Button from '../Button';


class LoginLink extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      email: props.email,
    };
    
    this.onLogin = this.onLogin.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
  }
   
  onLogin(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.sendLoginLink({
      email: this.state.email,
    });
  }

  onCreateAccount() {
    window.location.href = 'https://www.testive.com/free-practice-test';
  }
  
  render() {
    const { loginError, linkSent } = this.props;

    return (
      <div className={styles.login}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>online practice test</div>
          <div className={styles.subtitle}>Enter your email to resend your login link</div>
          {
            linkSent ? 
              <div className={styles.linkSent}>A login link has been sent to your email, please follow the instructions to begin your test</div>
            :
              <form className={styles.formContainer} onSubmit={this.onLogin}>
                <input 
                  name='email'
                  type='email' 
                  autoComplete='email'
                  placeholder='email'
                  maxLength='75'
                  required
                  onChange={(e) => this.setState({email: e.target.value})}
                  value={this.state.email}>
                </input>
                <ErrorMessage errors={loginError}/>  
            
                <div className={styles.buttonContainer}>
                  <input type='submit' value='SEND MY LINK'></input>
                  <Button 
                    ctaHighlight={false}
                    onClick={this.onCreateAccount}>
                    Sign Up
                  </Button>
                </div>
              </form>
          }
        </div>

      </div>
    );
  }
}

LoginLink.propTypes = {
  sendLoginLink: PropTypes.func.isRequired,
  history: PropTypes.object,
  email: PropTypes.string.isRequired,
  linkSent: PropTypes.bool.isRequired,
  loginError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

function mapStateToProps(state) {
  
  return {
    email: state.user.email,
    linkSent: state.user.loginLinkSent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendLoginLink: email => dispatch(sendLoginLink(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLink);