import fetch from 'isomorphic-fetch';
import { 
  USER_VERIFY_REQUEST, USER_VERIFY_SUCCESS, USER_VERIFY_FAILURE,
  STUDENT_ONBOARD_REQUEST, STUDENT_ONBOARD_SUCCESS, STUDENT_ONBOARD_FAILURE 
} from '../constants/actions';

function userVerifyRequest() {
  return {
    type: USER_VERIFY_REQUEST
  };
}

function userVerifySuccess(json, email) {
  return {
    type: USER_VERIFY_SUCCESS,
    receivedAt: Date.now(),
    data: json.data,
  };
}

function userVerifyFailure(error) {
  return {
    type: USER_VERIFY_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function verifyUser(form) {
  return function (dispatch) {
    dispatch(userVerifyRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];
    for (var key in form) {
      if (form.hasOwnProperty(key)) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(form[key]));
      }
    }

    console.log(formData);
  
    return fetch('https://app.testive.com/api/a/student_app_confirm_account/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(userVerifyFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(userVerifySuccess(json, form.email));
          } else if(json.status === 'error'){
            dispatch(userVerifyFailure(json.msg));
          }
        }
      );
  };
}

function studentOnboardRequest() {
  return {
    type: STUDENT_ONBOARD_REQUEST
  };
}

function studentOnboardSuccess(json, email) {
  return {
    type: STUDENT_ONBOARD_SUCCESS,
    receivedAt: Date.now(),
    data: json.data,
  };
}

function studentOnboardFailure(error) {
  return {
    type: STUDENT_ONBOARD_FAILURE,
    error: error.__all__[0],
    failureAt: Date.now()
  };
}

export function studentOnboard(form) {
  return function (dispatch) {
    dispatch(studentOnboardRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];
    for (var key in form) {
      if (form.hasOwnProperty(key)) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(form[key]));
      }
    }
  
    return fetch('https://app.testive.com/api/a/student_app_student_onboarding/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(studentOnboardFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(studentOnboardSuccess(json, form.email));
          } else if(json.status === 'error'){
            dispatch(studentOnboardFailure(json.msg));
          }
        }
      );
  };
}

