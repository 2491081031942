import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from'./Button.module.scss';

class Button extends React.Component {
  handleClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { children, className, ctaHighlight} = this.props;

    const buttonStyle = (ctaHighlight) 
      ? cx(styles.button, styles.cta)
      : styles.button;

    return (
      <button
        className={className !== undefined ? className : buttonStyle}
        disabled={this.props.disabled || false}
        onClick={(e) => this.handleClick(e)}>
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  ctaHighlight: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Button.defaultProps = {
  ctaHighlight: true,
  disabled: false
};

export default Button;
