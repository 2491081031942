import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Question from '../TestSectionAnswers/Question';
import GridinQuestion from '../TestSectionAnswers/GridinQuestion';
import Images from '../../assets/Images';

import styles from './TestSectionAnswersMobile.module.scss';


class TestSectionAnswersMobile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentQuestion: 0
    };

  }

  nextQuestion() {
    const { questionCount } = this.props;
    const { currentQuestion } = this.state;

    if(currentQuestion + 1 < questionCount) {
      this.setState({
        currentQuestion: currentQuestion + 1
      })
    }
  }

  previousQuestion() {
    const { questionCount } = this.props;
    const { currentQuestion } = this.state;

    if(currentQuestion > 0) {
      this.setState({
        currentQuestion: currentQuestion - 1
      })
    }
  }
  
  render() {
    const { questionCount, answerChoices, answers, gridinQuestions, testType } = this.props;

    let questionNum = this.state.currentQuestion + 1;
    var letters =  (questionNum % 2) === 0 ? answerChoices[1] : answerChoices[0];
    var answerSelected = answers[String(questionNum)] ? answers[String(questionNum)]: '';

    const nextStyle = (questionNum < questionCount) 
      ? styles.imageRight
      : cx(styles.imageRight, styles.disabled);
    const backStyle = (questionNum > 1) 
      ? styles.imageLeft
      : cx(styles.imageLeft, styles.disabled);
    const imgSize = 24;
    
    let q = '';
    if (questionNum <= questionCount){
      if(gridinQuestions && (questionNum >= gridinQuestions[0] && questionNum <= gridinQuestions[1])) {
        q = ( <GridinQuestion 
          chooseAnswer={this.props.chooseAnswer}
          key={questionNum.toString()}
          questionNumber={questionNum.toString()}
          answerOptions={letters}
          answerSelected={answerSelected}
          >
        </GridinQuestion>);
      }
      else {
        q = ( <Question 
          testType={testType}
          chooseAnswer={this.props.chooseAnswer}
          key={questionNum.toString()}
          questionNumber={questionNum.toString()}
          answerOptions={letters}
          answerSelected={answerSelected}
          >
        </Question>);
      }
    }
    return (
      <div className={styles.testSectionAnswers}>
        <img
          className={backStyle}
          onClick={this.previousQuestion.bind(this)}
          src={Images.back} 
          height={imgSize} width={imgSize} 
          alt={'back'}/>
        <div className={styles.questionContainer}>{q}</div>
        <img 
          className={nextStyle}
          onClick={this.nextQuestion.bind(this)}
          src={Images.next} 
          height={imgSize} width={imgSize} 
          alt={'next'}/>
      </div>);
  }
}

TestSectionAnswersMobile.propTypes = {
  questionCount: PropTypes.number.isRequired,
  answerChoices: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object.isRequired,
  gridinQuestions: PropTypes.array,
  testType: PropTypes.string,
};

TestSectionAnswersMobile.defaultProps = {
  questionCount: 75,
  answerChoices: [['A', 'B', 'C', 'D'], ['F', 'G', 'H', 'J']],
  answers: {}
};

export default TestSectionAnswersMobile;
