import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Timer from '../Timer';

import styles from './TestSectionDirections.module.scss';

const timerStyle = {
  color: '#ff0033',
  fontSize: '24p'
};

class TestSectionDirections extends React.Component {
    
  renderDirection(testTitle) {
    const { testType } = this.props;
    const ACTdirections = {
      'english':
        <div>
          <p><span>DIRECTIONS:</span> In the five passages that follow, certain words and phrases are underlined and numbered. In the right-hand column, you will find alternatives for the underlined part. In most cases, you are to choose the one that best expresses the idea, makes the statement appropriate for standard written English, or is worded most consistently with the style and tone of the passage as a whole. If you think the original version is best, choose “NO CHANGE.” In some cases, you will find in the right-hand column a question about the underlined part. You are to choose the best answer to the question.
          </p>
          <p>You will also find questions about a section of the passage, or about the passage as a whole. These questions do not refer to an underlined portion of the passage, but rather are identified by a number or numbers in a box.  
          </p>
          <p>For each question, choose the alternative you consider best and click the corresponding oval in the answer grid on the right side of the screen. Read each passage through once before you begin to answer the questions that accompany it. For many of the questions, you must read several sentences beyond the question to determine the answer. Be sure that you have read far enough ahead each time you choose an alternative.
          </p>
        </div>,
      'math':
        <div>
          <p><span>DIRECTIONS:</span> Solve each problem, choose the correct answer, and then click the corresponding oval in the answer grid on the right side of the screen.
          </p>
          <p>Do not linger over problems that take too much time. Solve as many as you can; then return to the others in the time you have left for this test.
          </p>
          <p>You are permitted to use a calculator on this test. You may use your calculator for any problems you choose, but some of the problems may best be done without using a calculator.
          </p>

          <span>Note: Unless otherwise stated, all of the following should be assumed.</span>
          <ol>
            <li>Illustrative figures are NOT necessarily drawn to scale.</li>
            <li>Geometric figures lie in a plane.</li>
            <li>The word line indicates a straight line.</li>
            <li>The word average indicates arithmetic mean.</li>
          </ol>
        </div> , 
      'reading':
        <div>
          <p><span>DIRECTIONS:</span> There are several passages in this test. Each passage is accompanied by several questions. After reading a passage, choose the best answer to each question and click the corresponding oval in the answer grid on the right side of the screen. You may refer to the passages as often as necessary.</p>
        </div>,
      'science':
        <div>
          <p><span>DIRECTIONS:</span> There are several passages in this test. Each passage is followed by several questions. After reading a passage, choose the best answer to each question and click the corresponding oval in the answer grid on the right side of the screen. You may refer to the passages as often as necessary.
          </p>
          <p>You are NOT permitted to use a calculator on this test.</p>
        </div>,
    };
    const SATdirections = {
      'math no calculator':
        <div>
          <p><span>DIRECTIONS:</span> For questions 1-15, solve each problem, choose the best answer from the choices provided. For questions 16-20, solve the problem and enter your answer in the grid. Prior to question 16, the test will display directions as to how to enter your answer in the grid.
          </p>
          <ol>
            <li>The use of a calculator is not permitted</li>
            <li>All variables and expressions used represent real numbers unless otherwise indicated.</li>
            <li>Figures provided in this test are drawn to scale unless otherwise indicated..</li>
            <li>All figures lie in a plane unless otherwise indicated.</li>
            <li>Unless otherwise indicated, the domain of a given function f is the set of all real numbers x for which f(x) is a real number.</li>
          </ol>
        </div> , 
      'math calculator': 
        <div>
          <p><span>DIRECTIONS:</span> For questions 1-30, solve each problem, then choose the best answer from the choices provided. For questions 31-38, solve the problem and enter your answer in the grid. Please refer to the directions before question 31 on how to enter your answers in the grid.
          </p>
          <ol>
            <li>The use of a calculator is permitted.</li>
            <li>All variables and expressions used represent real numbers unless otherwise indicated.</li>
            <li>Figures provided in this test are drawn to scale unless otherwise indicated..</li>
            <li>All figures lie in a plane unless otherwise indicated.</li>
            <li>Unless otherwise indicated, the domain of a given function f is the set of all real numbers x for which f(x) is a real number.</li>
          </ol>
        </div> , 
      'reading':
        <div>
          <p><span>DIRECTIONS:</span> Each passage or pair of passages is followed by a number of questions. After reading each passage or pair, choose the best answer to each question based on what is stated or implied in the passage or passages and in any accompanying graphics (such as a table or graph).</p>
        </div>,
      'writing and language':
          <div>
            <p><span>DIRECTIONS:</span> Each passage is accompanied by a number of questions. For some questions, you will consider how the passage might be revised to improve the expression of ideas. For other questions, you will consider how the passage might be edited to correct errors in sentence structure, usage, or punctuation. A passage or a question may be accompanied by one or more graphics (such as a table or graph) that you will consider as you make revising and editing decisions.</p>
            <p>Some questions will direct you to an underlined portion of a passage. Other questions will direct you to a location in a passage or ask you to think about the passage as a whole.</p>
            <p>After reading each passage, choose the answer to each question that most effectively improves the quality of writing in the passage or that makes the passage conform to the conventions of standard written English. Many questions include a “NO CHANGE” option. Choose that option if you think the best choice is to leave the relevant portion of the passage as it is.</p>
          </div>
    };
    if(testType === 'ACT') {
      return ACTdirections[testTitle];
    }
    else if(testType === 'SAT') {
      return SATdirections[testTitle];
    }
    return undefined;
  }

  render() {
    const { 
      testTitle, totalQuestions, 
      totalTime, remainingTime, 
      onDismiss } = this.props;

    

    return (
      <div className={styles.testSectionDirections}>
        <div className={styles.content}>
          <div className={styles.timer}>
            <div className={styles.timerWarning}>your time has started</div>
            <Timer
              notifyParent={function(){return;}}
              style={timerStyle}
              timeInSeconds={remainingTime}
              onTimerComplete={function(){return;}}
              timerText='REMAINING TIME'
            />
          </div>
                  
          <div className={styles.header}>
            <span className={styles.subject}>{testTitle}</span>
            <span className={styles.details}>{totalTime} minutes - {totalQuestions} questions</span>
          </div>
                    
          {this.renderDirection(testTitle)}
          <Button 
            ctaHighlight={true} 
            onClick={onDismiss}>
            GOT IT
          </Button>
        </div>
      </div>
    );
  }
}
    
TestSectionDirections.propTypes = {
  testType: PropTypes.string.isRequired,
  testTitle: PropTypes.string.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  totalTime: PropTypes.number.isRequired,
  remainingTime: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired, 
};
    
export default (TestSectionDirections);