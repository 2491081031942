import * as constants from '../constants/actions';

export function saveProps(props) {
  return {
    type: constants.SAVE_PROPS,
    props: props
  };
}

export function clearProps() {
  return {
    type: constants.CLEAR_PROPS
  };
}