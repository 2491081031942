import * as constants from '../constants/actions';
import * as emailjs from 'emailjs-com';

export function updateSessionState(time, pageNumber) {
  return {
    type: constants.UPDATE_SESSION_STATE,
    time: time,
    pageNumber: pageNumber
  };
}

export function skipSection(sectionIndex) {
  return {
    type: constants.SKIP_SECTION,
    sectionIndex: sectionIndex,
  };
}

export function completeBreak(sectionIndex) {
  return {
    type: constants.COMPLETE_BREAK,
    sectionIndex: sectionIndex,
  };
}

function completeTestSectionRequest() {
  return {
    type: constants.COMPLETE_SECTION_REQUEST,
  };
}

function completeTestSectionSuccess(data) {
  return {
    type: constants.COMPLETE_SECTION_SUCCESS,
    data: data,
  };
}

function completeTestSectionFailure(error) {
  let displayName = localStorage.getItem('displayName');
  let email = localStorage.getItem('email');
  let testData = localStorage.getItem('testAnswers');
  emailjs.send('default_service', 'practice_test_error', {
    email: email,
    name: displayName,
    error: JSON.stringify(error),
    test_data: JSON.stringify(testData),
  });
  return {
    type: constants.COMPLETE_SECTION_FAILURE,
    error: error,
  };
}

export function completeTestSectionPostError() {
  return {
    type: constants.COMPLETE_SECTION_POST_ERROR,
  };
}

export function completeTestSection(request) {
  return function(dispatch) {
    dispatch(completeTestSectionRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let data = [];
    for (var key in request) {
      if (request.hasOwnProperty(key) && !Array.isArray(request[key]) && typeof request[key] !== 'object') {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(request[key]));
      }
      else if(request.hasOwnProperty(key) && (Array.isArray(request[key]) || typeof request[key] === 'object')) {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(request[key])));
      }
    }
    return fetch('https://app.testive.com/api/testsession/practice/test/post-test-section/', {
      method: 'POST',
      body: data.join('&'),
      headers: header
    }).then(response => response.json()
    ).then(
      response => {
        if(response.status === 'ok') {
          dispatch(completeTestSectionSuccess(response.data));
        }
        else {
          dispatch(completeTestSectionFailure(response.msg));
        }
      },
      error => dispatch(completeTestSectionFailure(error))
    );
  }
}

export function setExtendedTime(value) {
  return {
    type: constants.SET_EXTENDED_TIME,
    value: value,
  };
}

export function setDoubleTime(value) {
  return {
    type: constants.SET_DOUBLE_TIME,
    value: value,
  };
}

export function changeTest() {
  return {
    type: constants.CHANGE_TEST,
  };
}

export function toggleInstructionModal() {
  return {
    type: constants.TOGGLE_INSTRUCTION_MODAL
  };
}

export function hideScoreWelcome() {
  return {
    type: constants.HIDE_SCORE_WELCOME
  };
}

function startSessionRequest(email, test) {
  return {
    type: constants.START_SESSION_REQUEST,
    email: email,
    test: test,
  };
}

function startSessionSuccess(test) {
  return {
    test: test,
    type: constants.START_SESSION_SUCCESS
  };
}

function startSessionFailure() {
  return {
    type: constants.START_SESSION_FAILURE
  };
}

export function startSession(email, test) {
  return function(dispatch) {
    dispatch(startSessionRequest(email, test));

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let data = [];
    data.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
    data.push(encodeURIComponent('test') + '=' + encodeURIComponent(test));
    return fetch('https://app.testive.com/api/testsession/practice/test/start/', {
      method: 'POST',
      body: data.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(startSessionFailure(error))
    ).then(
      function(json) {
        if(json.status === 'ok') {
          dispatch(startSessionSuccess(test));
        } else {
          dispatch(startSessionFailure(json.msg));
        }
      }
    );
  };
}

export function chooseTest(test) {
  return {
    test: test,
    type: constants.CHOOSE_TEST,
  };
}

export function pauseSession(timeSaved) {
  return {
    type: constants.PAUSE_SESSION,
    timeSaved: timeSaved
  };
}

export function resumeSession() {
  return {
    type: constants.RESUME_SESSION
  };
}

export function chooseAnswer(testSectionId, question, answer, timeSaved) {
  return {
    type: constants.CHOOSE_ANSWER,
    testSectionId: testSectionId,
    question: question,
    answer: answer,
    timeSaved: timeSaved
  };
}

function postAnswersRequest() {
  return {
    type: constants.POST_ANSWERS_REQUEST
  };
}

function postAnswersSuccess(json) {
  return {
    type: constants.POST_ANSWERS_SUCCESS,
    data: json.data
  };
}

function postAnswersFailure(json) {
  let displayName = localStorage.getItem('displayName');
  let email = localStorage.getItem('email');
  let testData = localStorage.getItem('testAnswers');
  emailjs.send('default_service', 'practice_test_error', {
    email: email,
    name: displayName,
    error: JSON.stringify(json),
    test_data: JSON.stringify(testData),
  });
  return {
    type: constants.POST_ANSWERS_FAILURE,
    error: json,
    failureAt: Date.now()
  };
}

export function postAnswers(request) {
  return function(dispatch) {
    dispatch(postAnswersRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let data = [];
    for (var key in request) {
      if (request.hasOwnProperty(key) && !Array.isArray(request[key]) && typeof request[key] !== 'object') {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(request[key]));
      }
      else if(request.hasOwnProperty(key) && (Array.isArray(request[key]) || typeof request[key] === 'object')) {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(request[key])));
      }
    }
    return fetch('https://app.testive.com/api/testsession/practice/test/post-full-test/', {
      method: 'POST',
      body: data.join('&'),
      headers: header
    }).then(response => response.json()).then(
      response => {
        if(response.status === 'ok') {
          dispatch(postAnswersSuccess(response));
        } else {
          dispatch(postAnswersFailure(response.msg));
        }
      },
      error => dispatch(postAnswersFailure(error))
    );
  };
}