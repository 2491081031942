import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import TestSectionAnswers from '../TestSectionAnswers';
import TestSectionAnswersMobile from '../TestSectionAnswersMobile';
import PDFViewer from './../PDFViewer';
import Timer from '../Timer';
import PoweredByTestive from '../common/PoweredByTestive';
import TestSectionDirections from '../TestSectionDirections';
import ConfirmationModal from '../common/ConfirmationModal';
import Images from '../../assets/Images';
import { updateSessionState, pauseSession, postAnswers, chooseAnswer, completeTestSection, toggleInstructionModal, skipSection} from '../../actions';

import styles from './PracticeTest.module.scss';

class PracticeTestSession extends React.Component {

  constructor(props) {
    super(props);
  
    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.onStepComplete = this.onStepComplete.bind(this);
    this.onSkipSection = this.onSkipSection.bind(this);
    this.chooseAnswer = this.chooseAnswer.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.pauseTest = this.pauseTest.bind(this);
    this.pauseLogic = this.pauseLogic.bind(this);
    this.state = {
      currentTime: 0,
      pauseModal: false,
      endSectionModal: false,
      skipSectionModal: false,
    };

  }

  toggleInstructions() {
    this.props.toggleInstructionModal();
  }

  updateTime(seconds) {
    let time = seconds;
    this.setState({
      currentTime: time,
    });
  }

  updatePage(currentPage) {
    this.props.updateSessionState(this.state.currentTime, currentPage);
  }
  
  pauseLogic() {
    const { testPaused } = this.props;

    if(!testPaused) {
      this.setState({pauseModal: true});
    }
    else {
      this.pauseTest();
    }
  }

  pauseTest() {
    this.props.pauseSession(this.state.currentTime);
    this.props.history.replace('/pause');
  }

  onStepComplete() {
    const { currentTestSection, userUUID, test, extendedTime, doubleTime, failedSubmission } = this.props;
    var totalTime;
    if(extendedTime) {
      totalTime = currentTestSection.extendedTime - this.state.currentTime;
    }
    else if(doubleTime) {
      totalTime = currentTestSection.doubleTime - this.state.currentTime;
    }
    else {
      totalTime = currentTestSection.time - this.state.currentTime;
    }

    this.setState({endSectionModal: false});

    // Resubmit whole test
    if(currentTestSection.isLastSection && failedSubmission) {
      let testAnswers = this.formatTestAnswers();
      let testTimes = this.formatTestTimes();
      let request = {};
      request.user_uuid = userUUID;
      request.test_type = test.testType;
      request.test_index = test.testIndex;
      request.test_answers = testAnswers;
      request.test_times = testTimes;
      request.is_section_complete = true;
      request.total_time = totalTime;
      this.props.postAnswers(request);
    }
    else {
      let sectionAnswers = this.formatSectionAnswers();
      let sectionTimes = this.formatSectionTimes();
      let request = {};
      request.user_uuid = userUUID;
      request.test_type = test.testType;
      request.test_index = test.testIndex;
      request.test_section = currentTestSection.subject.split(' ').join('_');
      request.section_answers = sectionAnswers;
      request.section_times = sectionTimes;
      request.is_section_complete = true;
      request.total_time = totalTime;
      this.props.completeTestSection(request);
    }

    if(!currentTestSection.isLastSection) {
      this.props.history.replace('/loading');
    }
    else {
      this.props.history.replace('/finish');
    }

  }

  onSkipSection() {
    const { currentTestSection } = this.props;
    this.props.skipSection(currentTestSection.stepIndex);
    if(currentTestSection.isLastSection) {
      this.props.history.replace('/finish');
    }
  }

  chooseAnswer(question, answer) {
    this.props.chooseAnswer(
      this.props.currentTestSection.testSectionId,
      question, 
      answer, 
      this.state.currentTime
    );
  }

  formatTestAnswers() {
    const { testAnswers, test } = this.props;
    let formattedSectionAnswers = {};
    for(const step of test.steps) {
      if(step.type === 'test') {
        let sectionAnswers = testAnswers[step.testSectionId];
        formattedSectionAnswers[step.subject.split(' ').join('_')] = {};
        sectionAnswers = sectionAnswers ? sectionAnswers: {};
        for (var i = 1; i <= step.questionCount; i++) {
          formattedSectionAnswers[step.subject.split(' ').join('_')][String(i)] = sectionAnswers[i] ? sectionAnswers[i] : 'None';
        }
      }
    }
    return formattedSectionAnswers;
  }

  formatTestTimes() {
    const { answerTimes, test } = this.props;
    let formattedSectionTimes = {};
    for(const step of test.steps) {
      if(step.type === 'test') {
        let sectionTimes = answerTimes[step.testSectionId];
        formattedSectionTimes[step.subject.split(' ').join('_')] = {};
        sectionTimes = sectionTimes ? sectionTimes: {};
        for (var i = 1; i <= step.questionCount; i++) {
          formattedSectionTimes[step.subject.split(' ').join('_')][String(i)] = sectionTimes[i] ? sectionTimes[i] : 0;
        }
      }
    }
    return formattedSectionTimes;
  }

  formatSectionAnswers() {  
    const { testAnswers, currentTestSection } = this.props;
    let testSectionAnswers = testAnswers[currentTestSection.testSectionId];
    testSectionAnswers = testSectionAnswers ? testSectionAnswers : {};
    var formattedSectionAnswers = {};
    for (var i = 1; i <= currentTestSection.questionCount; i++) {
      formattedSectionAnswers[String(i)] = (testSectionAnswers[i]) ? testSectionAnswers[i] : 'None';
    }

    return formattedSectionAnswers;
  }

  formatSectionTimes() {  
    const { answerTimes, currentTestSection } = this.props;
    let sectionAnswerTimes = answerTimes[currentTestSection.testSectionId];
    sectionAnswerTimes = sectionAnswerTimes ? sectionAnswerTimes : {};
    var formattedSectionTimes = {};
    for (var i = 1; i <= currentTestSection.questionCount; i++) {
      formattedSectionTimes[String(i)] = (sectionAnswerTimes[i]) ? sectionAnswerTimes[i] : 0;
    }

    return formattedSectionTimes;
  }

  getTestSectionRemainingTime() {
    const { currentTestSection, timeSaved, extendedTime, doubleTime } = this.props;
    var sectionTime;
    if(extendedTime) {
      sectionTime = currentTestSection.extendedTime;
    }
    else if(doubleTime) {
      sectionTime = currentTestSection.doubleTime;
    }
    else {
      sectionTime = currentTestSection.time;
    }
    return timeSaved ? timeSaved : sectionTime;
  }

  getTotalSectionMinutes() {
    const { currentTestSection, extendedTime, doubleTime } = this.props;
    var sectionTime;
    if(extendedTime) {
      sectionTime = currentTestSection.extendedTime;
    }
    else if(doubleTime) {
      sectionTime = currentTestSection.doubleTime;
    }
    else {
      sectionTime = currentTestSection.time;
    }
    return Math.floor(sectionTime/60);
  }

  renderHeader() {
    const { currentTestSection, timeSaved, extendedTime, doubleTime, test, isDesktop } = this.props;
    var timeInSeconds;
    if(extendedTime) {
      timeInSeconds = currentTestSection.extendedTime;
    }
    else if(doubleTime) {
      timeInSeconds = currentTestSection.doubleTime;
    }
    else {
      timeInSeconds = currentTestSection.time;
    }

    const pauseModal = <ConfirmationModal 
      text='Pausing is not allowed on the official ACT. If you pause you are no longer replicating the actual experience. Are you sure you want to pause?'
      onConfirm={this.pauseTest}
      onCancel={() =>this.setState({pauseModal: false})}
      confirmText='Yes, pause my test session'
      cancelText='No, continue my test'
    />;
    let minutes = Math.floor(this.state.currentTime/ 60);
    let seconds = this.state.currentTime % 60;
    const endSectionModal = <ConfirmationModal
      text={`Are you sure you want to complete this ${currentTestSection.subject}?  You still have ${minutes} minutes and ${seconds} seconds remaining. You cannot return to this test section.`}
      onConfirm={this.onStepComplete}
      onCancel={() => this.setState({endSectionModal: false})}
      confirmText={`Yes, complete the ${currentTestSection.subject}`}
      cancelText={`continue ${currentTestSection.subject}`}
    />;
    const skipSectionModal = <ConfirmationModal
      text={`Skip does not sumbit your answers and you will not be able return to this test section. Only use this if you have already completed this section before and wish to keep your score. Are you sure you want to skip ${currentTestSection.subject}? `}
      onConfirm={this.onSkipSection}
      onCancel={() => this.setState({skipSectionModal: false})}
      confirmText={`Yes, skip the ${currentTestSection.subject} section`}
      cancelText={`No, do not skip`}
    />;

    return (
      <div className={styles.testSectionHeader}>
        {this.state.pauseModal ? pauseModal : null}
        {this.state.endSectionModal ? endSectionModal : null}
        {this.state.skipSectionModal ? skipSectionModal : null}
        <div className={styles.testSectionTitle}>
          <img
            className={styles.buttonImage}
            style={{paddingRight: 8}}
            src={Images.info}
            width={15} height={15}
            alt={'instruction modal'}
            title={'instruction modal'}
            onClick={this.toggleInstructions}
          />
          <span className={styles.testSectionTitleSubject}>{test.testName} - {currentTestSection.subject}:</span>
          <span className={styles.testSectionTitleDetails}>{this.getTotalSectionMinutes()} minutes - {currentTestSection.questionCount} questions</span>
        </div>
        <div className={styles.headerButtons}>
          <Timer
            key={currentTestSection.subject}
            timeSaved={timeSaved}
            notifyParent={this.updateTime}
            timeInSeconds={timeInSeconds}
            onTimerComplete={this.onStepComplete}
            timerText={isDesktop ? 'Remaining Time:' : ''}
          />         
          <button 
            className={styles.sessionButton} 
            onClick={() => this.setState({skipSectionModal: true})}>
            SKIP
          </button> 
          <button
            className={styles.sessionButton}
            onClick={this.pauseLogic}>
            PAUSE
          </button>
          <button 
            className={styles.sessionButton} 
            onClick={() => this.setState({endSectionModal: true})}>
            SUBMIT
          </button> 
        </div>
      </div>
    );
  }

  renderTestSectionDirections() {
    const { currentTestSection, test } = this.props;

    return (
      <TestSectionDirections
        testType={test.testType}
        testTitle={currentTestSection.subject}
        totalTime={this.getTotalSectionMinutes()}
        totalQuestions={currentTestSection.questionCount}
        onDismiss={this.toggleInstructions}
        remainingTime={this.getTestSectionRemainingTime()}
      ></TestSectionDirections>
    );
  }

  render() {
    const { isDesktop, currentTestSection, testAnswers, pageSaved, showInstructionModal, test } = this.props;
  
    if (!currentTestSection) {
      return null;
    }

    const directionsModal = showInstructionModal 
      ? this.renderTestSectionDirections() 
      : null;

    var header = this.renderHeader();
    var testDiv = 
      isDesktop ?
      <div className={styles.spitScreen}>
        <div className={styles.testBooklet}>
          <PDFViewer
            test={test}
            notifyParent={this.updatePage}
            pageNumber={pageSaved ? pageSaved: currentTestSection.pdfStart}
            pageStart={currentTestSection.pdfStart}
            pageEnd={currentTestSection.pdfEnd}
          />
        </div>
        <div className={styles.answerSheet}>
          <TestSectionAnswers 
            testType={test.testType}
            gridinQuestions={currentTestSection.gridin}
            questionCount={currentTestSection.questionCount}
            answerChoices={currentTestSection.answerChoices}
            chooseAnswer={this.chooseAnswer}
            answers={testAnswers[currentTestSection.testSectionId]}>
          </TestSectionAnswers>
        </div>
      </div>
      :
      <div className={styles.spitScreen}>
        <div className={styles.testBooklet}>
          <PDFViewer
            test={test}
            notifyParent={this.updatePage}
            pageNumber={pageSaved ? pageSaved: currentTestSection.pdfStart}
            pageStart={currentTestSection.pdfStart}
            pageEnd={currentTestSection.pdfEnd}
          />
        </div>
        <div className={styles.answerSheetMobile}>
          <TestSectionAnswersMobile 
            testType={test.testType}
            gridinQuestions={currentTestSection.gridin}
            questionCount={currentTestSection.questionCount}
            answerChoices={currentTestSection.answerChoices}
            chooseAnswer={this.chooseAnswer}
            answers={testAnswers[currentTestSection.testSectionId]}>
          </TestSectionAnswersMobile>
        </div>
      </div>;

    return (
      <div className={styles.container}>
        <div className={styles.testSession}>
          {header}
          {testDiv}
          <PoweredByTestive history={this.props.history}/>
        </div>
        {directionsModal}
      </div>
    );
  }
}

PracticeTestSession.propTypes = {
  updateSessionState: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired,
  testAnswers: PropTypes.object.isRequired,
  answerTimes: PropTypes.object.isRequired,
  currentTestSection: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pauseSession: PropTypes.func.isRequired,
  postAnswers: PropTypes.func.isRequired,
  completeTestSection: PropTypes.func.isRequired,
  skipSection: PropTypes.func.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  extendedTime: PropTypes.bool.isRequired,
  doubleTime: PropTypes.bool.isRequired,
  testPaused: PropTypes.bool.isRequired,
  showInstructionModal: PropTypes.bool.isRequired,
  toggleInstructionModal: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  timeSaved: PropTypes.number,
  pageSaved: PropTypes.number,
  userUUID: PropTypes.string.isRequired,
  failedSubmission: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { testsession } = state;

  return {
    test: testsession.test,
    testPaused: (testsession.testSessionStatus === 'PAUSED'),
    testAnswers: testsession.testAnswers,
    answerTimes: testsession.answerTimes,
    timeSaved: testsession.timeSaved,
    pageSaved: testsession.pageSaved,
    extendedTime: testsession.extendedTime,
    doubleTime: testsession.doubleTime,
    showInstructionModal: testsession.showInstructionModal,
    userUUID: state.user.userUUID,
    email: state.user.email,
    name: state.user.displayName,
    failedSubmission: state.testsession.failedSubmission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSessionState: (timeSaved, pageSaved) => dispatch(updateSessionState(timeSaved, pageSaved)), 
    pauseSession: (timeSaved) => dispatch(pauseSession(timeSaved)),   
    postAnswers: request => dispatch(postAnswers(request)), 
    skipSection: (sectionIndex) => dispatch(skipSection(sectionIndex)),
    completeTestSection: (stepIndex, sectionIndex, answers) => dispatch(completeTestSection(stepIndex, sectionIndex, answers)), 
    chooseAnswer: (testSectionId, question, answer, timeSaved) => dispatch(chooseAnswer(testSectionId, question, answer, timeSaved)),
    toggleInstructionModal: request => dispatch(toggleInstructionModal(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTestSession);
