import { FORGOTPASSWORD_REQUEST, FORGOTPASSWORD_SUCCESS, FORGOTPASSWORD_FAILURE } from '../constants/actions';

function forgotPasswordRequest() {
  return {
    type: FORGOTPASSWORD_REQUEST
  };
}

function forgotPasswordFailure(error) {
  return {
    type: FORGOTPASSWORD_FAILURE,
    error: error
  };
}

function forgotPasswordSuccess(json) {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    data: json.data
  };
}

export function forgotPassword(request) {
  return function(dispatch) {
    dispatch(forgotPasswordRequest());

    let data = [];

    for (var key in request) {
      if (request.hasOwnProperty(key)) {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(request[key]));
      }
    }

    return fetch('https://app.testive.com/api/a/student_app_reset_password/',{
      method: 'POST',
      body: data.join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;'
      }
    }).then(
      response => response.json(),
      error => dispatch(forgotPasswordFailure(error))
    ).then(
      function(json) {
        if(json.status === 'ok') {
          dispatch(forgotPasswordSuccess(json));
        } else {
          dispatch(forgotPasswordFailure(json.msg));
        }
      }
    );
  };
}
