import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ConfirmReplace.module.scss';
import { userLogout, verifyUser, chooseTest } from '../../actions';
import { testMapper, testNameMapper } from '../../constants/tests';
// import Link from './../common/Link';
import Images from '../../assets/Images';
import Button from '../Button';


class ConfirmReplace extends React.Component {
  
  constructor(props) {
    super(props);

    this.userLogout = this.userLogout.bind(this);
    this.confirmedAccount = this.confirmedAccount.bind(this);
  }
   
  userLogout() {
    const { id, test, testId, testName } = this.props.match.params;
    this.props.userLogout();  
    this.props.verifyUser({ user_uuid: id });
    if(test) {
      this.props.chooseTest(testMapper(test, testId));
    }
    else if(testName) {
      this.props.chooseTest(testNameMapper(testName));
    }
  }
  
  confirmedAccount() {
    if(this.props.test) {
      this.props.history.replace('/welcome');
    }
    else {
      this.props.history.replace('/choosetest');
    }
  }

  componentDidUpdate() {
    const { isValidUserID, test } = this.props;
    if(isValidUserID) {
      if(this.props.test) {
        this.props.history.replace('/welcome');
      }
      else {
        this.props.history.replace('/choosetest');
      }
    }
  }

  render() {
    const { email, displayName } = this.props;

    return (
      <div className={styles.confirmUser}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>An error occured when logging in to the test</div>
          <div className={styles.displayName}>There is already an account logged in</div>
          <div className={styles.explanation}>Would you like to continue as {email} or log in as a new user?</div>
          <div className={styles.subTitle}>Logging in as a new user will reset all currently active tests</div>
          <div className={styles.buttonContainer}>
            <Button 
              ctaHighlight={true}
              onClick={this.confirmedAccount}>
              Continue as {email}
            </Button>
            <Button 
              ctaHighlight={false}
              onClick={this.userLogout}>
              Log in to the new account
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmReplace.propTypes = {
  userLogout: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  chooseTest: PropTypes.func.isRequired,
  isValidUserID: PropTypes.bool,
  test: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    email: state.user.email,
    displayName: state.user.displayName,
    test: state.testsession.test,
    isValidUserID: state.user.isValidUserID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: request => dispatch(userLogout(request)), 
    verifyUser: request => dispatch(verifyUser(request)),
    chooseTest: test => dispatch(chooseTest(test))
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmReplace)