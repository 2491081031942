import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { setExtendedTime, setDoubleTime, resumeSession, userLogout, startSession } from '../../actions';
import PoweredByTestive from '../common/PoweredByTestive';
import Button from '../Button';

import styles from './WelcomeScreen.module.scss';
import TestOutline from '../TestOutline';

class WelcomeScreen extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.startTest = this.startTest.bind(this);
    this.setExtendedTime = this.setExtendedTime.bind(this);
    this.setDoubleTime = this.setDoubleTime.bind(this);
    this.resumeTest = this.resumeTest.bind(this);
    this.viewScores = this.viewScores.bind(this);
    this.resetTest = this.resetTest.bind(this);
  }

  setExtendedTime(event) {
    this.props.setExtendedTime(event.target.checked);
  }

  setDoubleTime(event) {
    this.props.setDoubleTime(event.target.checked);
  }

  startTest() {
    this.props.history.push('/start');
  }

  resumeTest() {
    this.props.resumeSession();
    this.props.history.push('/test');
  }

  viewScores() {
    const { userUUID, test, loginURL } = this.props;
    let testType = 'ACT';
    if(test.testType === 'SAT') {
      testType = 'NEW_SAT';
    }
    const testive_url = loginURL + '&redirect_to=/practice-test-analyze/' + testType + '/' + test.testIndex + '/' + userUUID + '/';
    window.open(testive_url,'_blank');     
  }

  resetTest() {
    const warning = 'Warning: This will reset all of your testing data. You will lose all of your previous answers for this test and your data will be irrecoverable. If you press OK all of your testing data will be erased and you will be logged out from the app. Do this only if you are absolutely sure. Proceed?';
    if (window.confirm(warning)) {
      this.props.logout();
      this.props.history.replace('/login');
    }
  }

  testText(ACTText, SATText) {
    const testType = this.props.test.testType;

    if(testType === 'ACT') {
      return ACTText;
    }
    else if(testType === 'SAT') {
      return SATText;
    }

  }

  displayBody() {
    const {extendedTime, doubleTime, test, userUUID, testSessionStatus, testStartDateStamp} = this.props;
    const testStarted = testStartDateStamp ? true : false;
    const finishedTest = (testSessionStatus === 'COMPLETE');
    const previousV3Test = this.props.previousTest;

    const testOutline =  
      <div className={styles.testOutline}>
        <TestOutline
          testInfo={this.props.test}
          currentStepIndex={this.props.currentStepIndex}
          currentTimer={this.props.currentTimer}
          doubleTime={doubleTime}
          extendedTime={extendedTime}>
        </TestOutline>
      </div>;

    const extendedTimeCheck =  
      <label className={styles.checkContainer} htmlFor='extended'>
        <input
          className={styles.checkbox}
          type='checkbox'
          id='extended'
          checked={extendedTime}
          disabled={testStarted}
          onChange={this.setExtendedTime}/>
          Check this box if you will be given extended (x1.5) time on the official exam.
      </label>;

    const doubleTimeCheck =  
      <label className={styles.checkContainer} htmlFor='double'>
        <input
          className={styles.checkbox}
          type='checkbox'
          id='double'
          checked={doubleTime}
          disabled={testStarted}
          onChange={this.setDoubleTime}/>
          Check this box if you will be given double time on the official exam.
      </label>;
    
    const smallScreenWarning = 
      <div className={styles.smallScreenWarning}>
        Warning: The practice test is meant to be taken at a computer to better replicate the real testing environment. If you are on a computer, please full screen the testing window. If you are not on a computer, the test will still work on your device if you choose to continue, but will not be the optimal testing environment.
      </div>;

    if (finishedTest) {
      return (
        <div className={styles.topContainer}>
          <div className={styles.title}>Welcome back to the {test.testType} Practice Test powered by Testive</div>
          {smallScreenWarning}
          <div className={styles.instructionSet}>
            <div className={styles.instructions}>
              <p>It looks as though you have already completed this {test.testType} Practice Test.</p>
              <p>We also offer an online {this.testText('SAT', 'ACT')} through this portal, if you wish to take another practice test please logout</p>
              <p>To view your score for the previous test, please press the <span className={styles.ctaCallOut}>VIEW SCORES</span> button to see your score report in the Testive app.</p>
              <p>To reset the data, please press the exit button in the bottom left or the reset test button below.</p>
            </div>
          </div>
    
          <div className={styles.buttonContainer}>
            <Button 
              onClick={this.viewScores}>
              VIEW SCORES
            </Button>
            <Button
              onClick={this.resetTest}
              ctaHighlight={false}>
              RESET TEST
            </Button>
          </div>
        </div>
      );
    }
    else if (testStarted) {
      return (
        <div className={styles.topContainer}>
          <div className={styles.title}>Welcome back to the {test.testType} Practice Test powered by Testive</div>
          {smallScreenWarning}
          <div className={styles.instructionSet}>
            <p>Your previous testing data has been saved and will be loaded when you resume the test.
              All of your answers from previous sections have been restored, in addition to any questions answered in your current section
              We will begin the test on the page of the test you left, and we have restored the timer to it&#39;s previous time.</p>
            <p>When you are ready to begin, please press the <span className={styles.ctaCallOut}>Resume Test</span> button.</p>
          </div>
         
          {testOutline}
          
          <div className={styles.buttonContainer}>
            <Button 
              className={styles.startButton}
              onClick={this.resumeTest}>
              RESUME TEST 
            </Button>
          </div>
        </div>
      );
    }
    else {
      let testType = 'ACT';
      if(test.testType === 'SAT') {
        testType = 'NEW_SAT';
      }
      const testive_url = 'https://app.testive.com/practice-test-analyze/' + testType + '/' + test.testIndex + '/' + userUUID + '/';
      const testiveLink = <a href={testive_url} target="_blank" rel="noopener noreferrer">Here</a>;
      return (
        <div className={styles.topContainer}>
          <div className={styles.title}> Welcome to the {test.testType} Practice Test powered by Testive</div>     
          { previousV3Test ?   
            <div className={styles.warning}>It looks as though you have already completed this practice test. Completing this test again will erase all of your data from the previous test. Click {testiveLink} to View Your Scores.</div>
            : null
          }
          {smallScreenWarning}
          <div className={styles.topContainerSplit}>
            <div className={styles.left}>
              <div className={styles.instructionSet}>
                <div className={styles.instructions}>
                  <p>This test has four sections: {this.testText('English, Mathematics, Reading, and Science', 'Reading, Writing and Language, Math - No Calculator, and Math - Calculator')}. It will last {this.testText('2 hours and 55 minutes, plus 10 minutes for a break between the Mathematics and Reading tests', '3 hours plus two short breaks (10 minutes after the Reading section and 5 minutes after the first Math section)')}.
                  </p>
                  <p>Please prepare to be focused on the test for the whole time. If you do not have three uninterrupted hours available right now, you should take the practice test when you do.
                  </p>
                  <p>This practice test is meant to replicate the real testing environment. It is timed and you will not have the ability to access sections other than the one you are currently working on.
                  </p>
                  <p>Before you begin, make sure that you have scratch paper, a writing utensil, a calculator, and anything else you may need (like a water bottle).
                  </p>
                  <p>If you experience an unexpected interruption during the test, such as losing your internet connectivity, the test is designed to save your place so that you can automatically resume by coming back to this page. To be safe, you should take a screenshot of your answer sheet if you have to refresh your browser or exit the test for any reason.
                  </p>          
                </div>
                {extendedTimeCheck}
                {doubleTimeCheck}
              </div>
            </div>
            <div className={styles.right}>
              {testOutline}
    
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button 
              onClick={this.startTest}>
                GET STARTED
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className={styles.testInstructions}>
        {this.displayBody()}
        <div className={styles.footer}>
          <PoweredByTestive history={this.props.history}/>
        </div>
      </div>
    );
  }
}
  
WelcomeScreen.propTypes = {
  test: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  setExtendedTime: PropTypes.func.isRequired,
  setDoubleTime: PropTypes.func.isRequired,
  extendedTime: PropTypes.bool.isRequired,
  doubleTime: PropTypes.bool.isRequired,
  testAnswers: PropTypes.object.isRequired,
  testSessionStatus: PropTypes.string.isRequired,
  previousTest: PropTypes.bool.isRequired,
  currentTimer: PropTypes.number,
  resumeSession: PropTypes.func.isRequired,
  startSession: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  userUUID: PropTypes.string.isRequired,
  loginURL:  PropTypes.string.isRequired,
  testStartDateStamp: PropTypes.instanceOf(Date)
};
  
function mapStateToProps(state){
  return {
    test: state.testsession.test,
    email: state.user.email,
    currentStepIndex: state.testsession.currentStepIndex,
    extendedTime: state.testsession.extendedTime,
    doubleTime: state.testsession.doubleTime,
    testAnswers: state.testsession.testAnswers,
    testSessionStatus: state.testsession.testSessionStatus,
    testStartDateStamp: state.testsession.testStartDateStamp,
    previousTest: state.user.completedTest,
    currentTimer: state.testsession.timeSaved,
    userUUID: state.user.userUUID,
    loginURL: state.user.loginURL
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setExtendedTime: request => dispatch(setExtendedTime(request)),
    setDoubleTime: request => dispatch(setDoubleTime(request)),
    resumeSession: request => dispatch(resumeSession(request)),
    startSession: (email, test) => dispatch(startSession(email, test)),
    logout: request => dispatch(userLogout(request))
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);
  