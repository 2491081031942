import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { format } from 'date-fns';

import { userLogout, postAnswers } from '../../actions';
import Button from '../Button';
import TestSessionSubmit from '../TestSessionSubmit';
import ErrorMessage from '../common/ErrorMessage';

import styles from './TestCompleted.module.scss';

class TestCompleted extends React.Component {
  
  constructor(props) {
    super(props);
    this.viewMyScores = this.viewMyScores.bind(this);
    this.goToExperimental = this.goToExperimental.bind(this);
    this.exitScores = this.exitScores.bind(this);
  }

  exitScores() {
    this.props.history.replace('/');
  }

  viewMyScores() {
    const { userUUID, test, loginURL } = this.props;
    let testType = 'ACT';
    if(test.testType === 'SAT') {
      testType = 'NEW_SAT';
    }
    const testive_url = loginURL + '&redirect_to=/practice-test-analyze/' + testType + '/' + test.testIndex + '/' + userUUID + '/';
    window.open(testive_url, '_blank'); 
    this.props.userLogout();  
    this.props.history.replace('/');  
  }

  goToExperimental() {
    const { userUUID, test, loginURL } = this.props;
    let testType = 'ACT';
    if(test.testType === 'SAT') {
      testType = 'NEW_SAT';
    }
    const testive_url = loginURL + '&redirect_to=/shuffle-practice-test/' + testType + '/?analyze_url=/practice-test-analyze/' + testType + '/' + test.testIndex + '/' + userUUID + '/';
    window.open(testive_url, '_blank');

    this.props.userLogout();  
    this.props.history.replace('/');  
  }
  
  render() {
    const { 
      test,
      email,
      submittingResults,
      sectionError, 
      error, 
      testAnswers,
      testSessionStatus } = this.props;

    if (testSessionStatus !== 'COMPLETED' && !submittingResults) {
      let answerLength = Object.keys(testAnswers).length;
      if(answerLength > 0) {
        const testType = this.props.test.testType;
        return (
          <div className={styles.testCompleted}>
            <div className={styles.content}>
              <ErrorMessage errors={sectionError}/>
              <div className={styles.subTitle}>Something went wrong when submitting your test</div>
              <div className={styles.testiveIntro}>Don't worry, your answers have been saved. Please leave this window open and go to your <a target="_blank" href={"https://www.testive.com/" + testType + "-proctored-test/"}>Test Day Support Page</a> to contact our support staff and help finish your test</div>
            </div>
          </div>);  
      }
      else {
        return (
          <div className={styles.testCompleted}>
            <div className={styles.content}>
              <div className={styles.subTitle}>No Test Results Found</div>
              <div className={styles.testiveIntro}>If you were expecting your results to display please email <a href="mailto:success@testive.com">success@testive.com</a>. Otherwise please restart the test below.</div>
              <div className={styles.buttonContainer}>
                <Button
                  onClick={this.exitScores}>
                  Exit Test
                </Button>
              </div>
            </div>
          </div>); 
      }
    }
    if (sectionError) {
      const testType = this.props.test.testType;
      return (
        <div className={styles.testCompleted}>
          <div className={styles.content}>
            <ErrorMessage errors={sectionError}/>
            <div className={styles.subTitle}>Something went wrong when submitting your test</div>
            <div className={styles.testiveIntro}>Don't worry, your answers have been saved. Please leave this window open and go to your <a target="_blank" href={"https://www.testive.com/" + testType + "-proctored-test/"}>Test Day Support Page</a> to contact our support staff and help finish your test</div>
          </div>
        </div>);  
    }
    else if (submittingResults) 
    {
      return (<TestSessionSubmit/>); 
    }

    return (
      <div className={styles.testCompleted}>
        <div className={styles.content}>
          <div className={styles.title}>Congratulations</div>
          <div className={styles.subTitle}>You completed a practice {test.testType}</div>
          <div className={styles.testiveIntro}>
            You may view your scores online by logging in to Testive with your email: <span>{email}</span>.
            Testive also offers free ACT and SAT practice questions you can use to continue prepping. 
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={this.viewMyScores}>
              View Scores
            </Button>
          </div>
        </div>
      </div>
    );
  }  
}
  
TestCompleted.propTypes = {
  test: PropTypes.object.isRequired,
  testAnswers: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  userUUID: PropTypes.string.isRequired,
  loginURL: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  submittingResults: PropTypes.bool.isRequired,
  error: PropTypes.object,
  sectionError: PropTypes.object,
  testSessionStatus: PropTypes.string.isRequired,
  userLogout: PropTypes.func.isRequired, 
  postAnswers: PropTypes.func.isRequired,
};
  
function mapStateToProps(state){
  return {
    test: state.testsession.test,
    userUUID: state.user.userUUID,
    loginURL: state.user.loginURL,
    email: state.user.email,
    submittingResults: state.testsession.resultsLoading,
    error: state.testsession.error,
    sectionError: state.testsession.sectionError,
    testSessionStatus: state.testsession.testSessionStatus,
    testAnswers: state.testsession.testAnswers,
    name: state.user.displayName,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userLogout: request => dispatch(userLogout(request)), 
    postAnswers: request => dispatch(postAnswers(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestCompleted);
  