import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { testNames } from './constants/tests';

import Login from './components/Login';
import Register from './components/Register';
import LoginLink from './components/LoginLink';
import EnsureLoggedIn from './components/EnsureLoggedIn';
import ResetPassword from './components/ResetPassword';
import LaunchScreen from './components/LaunchScreen';
import TestCompleted from './components/TestCompleted';
import WelcomeScreen from './components/WelcomeScreen';
import TestSessionPause from './components/TestSessionPause';
import TestSession from './components/TestSession';
import ChooseTest from './components/ChooseTest/ChooseTest';
import ConfirmUser from './components/ConfirmUser';
import VerifyUser from './components/VerifyUser';
import InstantVerify from './components/InstantVerify';
import StudentOnboarding from './components/StudentOnboarding';
import ConfirmReplace from './components/ConfirmReplace';
import SubmittingAnswers from './components/SubmittingAnswers';

import styles from './app.module.scss';

class App extends Component {
  render() {
    
    return (
      <div className={styles.appMain}>     
        <BrowserRouter>
          <Switch>
            <Route exact path='/register' component={Register} />
            <Route exact path='/login' component={LoginLink} />
            <Route exact path='/getstarted/:id' component={VerifyUser} />
            <Route exact path={'/verify/:id/:testName('+testNames()+')?'} component={InstantVerify} />
            <Route exact path='/verify/:id/:test?/:testId(\d+)?' component={InstantVerify} />
            <Route exact path={'/replace/:id/:testName('+testNames()+')'} component={ConfirmReplace} />
            <Route exact path='/replace/:id/:test?/:testId(\d+)?' component={ConfirmReplace} />
            <Route exact path='/onboarding' component={StudentOnboarding} />
            <Route exact path='/resetpassword' component={ResetPassword} />
            <Route exact path='/choosetest' component={EnsureLoggedIn(ChooseTest)} />
            <Route exact path='/' component={EnsureLoggedIn(ConfirmUser)} />
            <Route exact path='/:test(sat|act)/:id(\d+)' component={EnsureLoggedIn(ConfirmUser)} />
            <Route exact path='/welcome' component={EnsureLoggedIn(WelcomeScreen)} />
            <Route exact path='/start' component={EnsureLoggedIn(LaunchScreen)} />
            <Route exact path='/test' component={EnsureLoggedIn(TestSession)} /> 
            <Route exact path='/loading' component={EnsureLoggedIn(SubmittingAnswers)} />
            <Route exact path='/pause' component={EnsureLoggedIn(TestSessionPause)} />
            <Route exact path='/finish' component={EnsureLoggedIn(TestCompleted)} />
            <Route component={Login}/>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
