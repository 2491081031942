import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TestOutline from '../TestOutline';
import Timer from '../Timer';
import Button from '../Button';
import { completeBreak, updateSessionState } from '../../actions';
import styles from './TestSessionBreak.module.scss';


const style = {
  color: 'white',
  width: 'fit-content',
  margin: '10px 0',
  fontSize: '28px',
};

class TestSessionBreak extends React.Component {
  
  constructor(props) {
    super(props);
    this.onBreakComplete = this.onBreakComplete.bind(this);

    const t0 = props.timerStart;
    var elapsedTime = 0;
    if (!isNaN(t0)) { 
      const timeDiffMs = new Date().getTime() - t0.getTime();
      elapsedTime = Math.floor(timeDiffMs/1000);
    }
    
    var breakTime;
    if(props.extendedTime) {
      breakTime = props.currentStep.extendedTime;
    }
    else if(props.doubleTime) {
      breakTime = props.currentStep.doubleTime;
    }
    else {
      breakTime = props.currentStep.time;
    }
    const remainingTime = breakTime - elapsedTime;
    this.state = {
      time: remainingTime
    };
  }

  onBreakComplete() {
    const { currentStep } = this.props;
    this.props.completeBreak(currentStep.stepIndex);
  }

  render() {
    const { test, extendedTime, doubleTime, currentStep } = this.props;
    const { time } = this.state;

      var breakTime;
      if(extendedTime) {
        breakTime = Math.floor(currentStep.extendedTime/60);
      }
      else if(doubleTime) {
        breakTime = Math.floor(currentStep.doubleTime/60);
      }
      else {
        breakTime = Math.floor(currentStep.time/60);
      }

    var timer = <Timer
      notifyParent={function(){return;}}
      style={style}
      timeInSeconds={time}
      onTimerComplete={this.onBreakComplete}
      timerText=''/>;

    return (
      <div className={styles.testSessionBreak}>
        <div className={styles.content}>
          <div className={styles.title}>{breakTime} MINUTE BREAK</div>
          <div className={styles.subTitle}>the next test section starts in: {timer}</div>
          <div className={styles.info}>
            This is a timed rest between sections. Feel free to get up, use the bathroom, 
            drink some water or eat a snack. The test will resume when the timer runs out.
          </div>
          <TestOutline
            testInfo={test}
            extendedTime={extendedTime}
            doubleTime={doubleTime}
            currentStepIndex={currentStep.stepIndex}
          ></TestOutline>
          <div className={styles.buttonContainer}>
            <Button
              onClick={this.onBreakComplete}
              ctaHighlight={false}>
              START NEXT SECTION NOW
            </Button>
          </div>
        </div>
      </div>
    );
  }  
}
    
TestSessionBreak.propTypes = {
  test: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
  extendedTime: PropTypes.bool.isRequired,
  doubleTime: PropTypes.bool.isRequired,
  updateSessionState: PropTypes.func.isRequired,
  timerStart: PropTypes.instanceOf(Date)
};
    
function mapStateToProps(state) {
  const { testsession } = state;

  return {
    test: testsession.test,
    currentStep: testsession.currentStep,
    extendedTime: testsession.extendedTime,
    doubleTime: testsession.doubleTime,
    timerStart: testsession.timerStart,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    completeBreak: () => dispatch(completeBreak()),
    updateSessionState: request => dispatch(updateSessionState(request))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSessionBreak);