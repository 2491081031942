import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as emailjs from 'emailjs-com';

import styles from './PoweredByTestive.module.scss';
import Images from './../../../assets/Images';
import Link from './../Link';
import ConfirmationModal from '../ConfirmationModal';
import { userLogout, changeTest } from '../../../actions';


class PoweredByTestive extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showLogoutModal: false,
      showDataModal: false,
      changeTestModal: false,
    };

    this.logout = this.logout.bind(this);
    this.sendData = this.sendData.bind(this);
    this.changeTest = this.changeTest.bind(this);

    const warning = 'This will reset all of your testing data. You will lose all of your previous answers for this test and your data will be irrecoverable. If you press OK all of your testing data will be erased and you will be logged out from the app. Do this only if you are absolutely sure. Proceed?';
    this.logoutModal = <ConfirmationModal 
      text={warning}
      onConfirm={this.logout}
      onCancel={() => this.setState({showLogoutModal: false})}
    />;

    const dataWarning = 'This will send all of your test data to our tech support staff. Only use this if you are currently talking to our support team and they have requested you send us your data.';
    this.dataModal= <ConfirmationModal 
      text={dataWarning}
      onConfirm={this.sendData}
      onCancel={() => this.setState({showDataModal: false})}
    />;

    const changeTestWarning = 'Changing tests will get clear any completed sections for the current test. This data cannot be recovered, only change tests if you have already completed the current test before, or have no intention of finishing it. Continue?';
    this.changeTestModal= <ConfirmationModal 
      text={changeTestWarning}
      onConfirm={this.changeTest}
      onCancel={() => this.setState({showChangeTestModal: false})}
    />;
  }

  sendData() {
    const {name, email, state} = this.props;
    emailjs.send('default_service', 'practice_test_data_dump',
      {
        email: email,
        name: name,
        localStorage: JSON.stringify(localStorage),
        state: JSON.stringify(state)
      }
    );
    this.setState({showDataModal: false});
  }

  logout() {
    this.props.userLogout();
    this.props.history.replace('/login');
  }

  changeTest() {
    this.props.changeTest();
    this.props.history.replace('/choosetest');
  }
 
  render() {
    const { email } = this.props;
    return (
      <div className={styles.container}>
        {this.state.showLogoutModal ? this.logoutModal : null}
        {this.state.showDataModal ? this.dataModal : null}
        {this.state.showChangeTestModal ? this.changeTestModal: null}
        <div className={styles.left}>
          <Link onPress={() => this.setState({showLogoutModal: true})} text='Quit'/>
          <Link onPress={() => this.setState({showChangeTestModal: true})} text='Change Test'/>
          <div className={styles.text}>{email}</div>
        </div>
        <div className={styles.right}>
          <img 
            onClick={() => this.setState({showDataModal: true})}
            className={styles.logo} 
            src={Images.logoPoweredBy} 
            alt='Powered by Testive'>
          </img>  
        </div>
      </div>
    );
  }
}

PoweredByTestive.propTypes = {
  history: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
  changeTest: PropTypes.func.isRequired,
  test: PropTypes.object,
  email: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    email: state.user.email,
    name: state.user.displayName,
    test: state.testsession.test,
    state: state,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    userLogout: request => dispatch(userLogout(request)), 
    changeTest: () => dispatch(changeTest()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PoweredByTestive);