import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './Register.module.scss';
import { registerUser } from './../../actions';
import Link from './../common/Link';
import ErrorMessage from './../common/ErrorMessage';
import Images from '../../assets/Images';

class Register extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      formErrors: [],
      studentFirstName: '',
      studentLastName: '',
      studentEmail: '',
      password1: '',
      password2: '',
      parentFirstName: '',
      parentLastName: '',
      parentEmail: '',
      parentPhone: '',
      gradYear: '',
    };
    
    this.onRegister = this.onRegister.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }
  
  componentDidUpdate() {
    if(this.props.isAuthenticated) {
      this.props.history.replace('/choosetest');
    }
  }
  
  validateForm() {
    var errors = [];
    var valid = true;
    if (this.state.password1 !== this.state.password2) {
      errors.push('Password and confirm password must match');
      valid = false;
    }
    if (this.state.parentEmail === this.state.studentEmail) {
      errors.push('Parent and student emails must be unique');
      valid = false;
    }
    
    this.setState({formErrors: errors});
    return valid;  
  }
  
  onRegister(event) {
    event.preventDefault();
    event.stopPropagation();
    // need to register the parent and the student
    // and link the two accounts with user supporter
    if (this.validateForm()) {
      this.props.registerUser({
        student_email: this.state.studentEmail,
        student_first_name: this.state.studentFirstName,
        student_last_name: this.state.studentLastName,
        password1: this.state.password1,
        password2: this.state.password2,
        parent_email: this.state.parentEmail,
        parent_first_name: this.state.parentFirstName,
        parent_last_name: this.state.parentLastName,
        parent_phone: this.state.parentPhone,
        grad_year: this.state.gradYear,
      });
    }
  }
  
  onLogin() {
    this.props.history.push('/login');
  }
  
  render() {
    return (
      <div className={styles.register}>
        <div className={styles.mainContainer}>
          <div className={styles.errorMessage}>
            <ErrorMessage errors={this.props.errors}/>
            <ErrorMessage errors={this.state.formErrors}/>
          </div>
          <div className={styles.logoContainer}>
          <img src={Images.logoSlyAndText} height={80} alt='testive'></img>
          </div>
          <div className={styles.title}>online practice test</div>
          <div className={styles.subTitle}>To continue please register for an account or login below</div>
          <form className={styles.registrationForm} onSubmit={this.onRegister}>
            <div className={styles.forms}>
              <div className={styles.formContainer}>
                <div className={styles.formHeader}>Your Parent/Guardian:</div>
                <input 
                  name='parentFirstName'
                  type='text' 
                  autoComplete='given-name'
                  placeholder='parent/guardian first name'
                  maxLength='30'
                  required
                  onChange={(e) => this.setState({parentFirstName: e.target.value})}>
                </input>
                <input 
                  name='parentLastName'
                  type='text' 
                  autoComplete='family-name'
                  placeholder='parent/guardian last name'
                  maxLength='30'
                  required
                  onChange={(e) => this.setState({parentLastName: e.target.value})}>
                </input>
                <input 
                  name='parentEmail'
                  type='email' 
                  autoComplete='email'
                  placeholder='parent/guardian email'
                  maxLength='75'
                  required
                  onChange={(e) => this.setState({parentEmail: e.target.value})}>
                </input>
                <input 
                  name='parentPhone'
                  type='tel' 
                  autoComplete='tel-national'
                  placeholder='parent/guardian phone number'
                  required
                  onChange={(e) => this.setState({parentPhone: e.target.value})}>
                </input>
              </div>
              <div className={styles.formContainer}>
              <div className={styles.formHeader}>Your Student Account:</div>
              <input 
                name='firstname'
                type='text' 
                autoComplete='given-name'
                placeholder='first name'
                maxLength='30'
                required
                onChange={(e) => this.setState({studentFirstName: e.target.value})}>
              </input>
              <input 
                name='lastname'
                type='text' 
                autoComplete='family-name'
                placeholder='last name'
                maxLength='30'
                required
                onChange={(e) => this.setState({studentLastName: e.target.value})}>
              </input>
              <input 
                name='email'
                type='email' 
                autoComplete='email'
                placeholder='email'
                maxLength='75'
                required
                onChange={(e) => this.setState({studentEmail: e.target.value})}>
              </input> 
              <select 
                name='gradYear' 
                required
                defaultValue=''
                onChange={(e) => this.setState({gradYear: e.target.value})}>
                <option value='' disabled hidden>student grad year</option>
                <option value='2019'>2019</option>
                <option value='2020'>2020</option>
                <option value='2021'>2021</option>
                <option value='2022'>2022</option>
                <option value='2023'>2023+</option> 
              </select>           
              <input 
                name='password1'
                type='password' 
                autoComplete='new-password'
                placeholder='password'
                maxLength='128'
                required
                onChange={(e) => this.setState({password1: e.target.value})}>
              </input>
              <input 
                name='password2'
                type='password' 
                autoComplete='new-password'
                placeholder='confirm password'
                maxLength='128'
                required
                onChange={(e) => this.setState({password2: e.target.value})}>
              </input>   
            </div>
            </div>
            <div className={styles.buttonContainer}>
              <input className={styles.registerButton} type='submit' value='REGISTER'></input>
            </div>
          </form>
          <div className={styles.loginLink}>
            If you are already registered <Link 
              onPress={this.onLogin}
              text='login here'></Link>
          </div>
        </div>
      </div>
    );
  }
}
  
Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  loginError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};
  
function mapStateToProps(state) {
  const { errors, isAuthenticated } = state.user;
    
  return {
    errors: errors,
    isAuthenticated: isAuthenticated,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    registerUser: request => dispatch(registerUser(request)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Register);