import { FORGOTPASSWORD_REQUEST, FORGOTPASSWORD_SUCCESS, FORGOTPASSWORD_FAILURE } from '../constants/actions';
import { REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS } from '../constants/actions';

function forgotPasswordRequest() {
  return {
    type: FORGOTPASSWORD_REQUEST
  };
}

function forgotPasswordFailure(error) {
  return {
    type: FORGOTPASSWORD_FAILURE,
    error: error
  };
}

function forgotPasswordSuccess(json) {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    data: json.data
  };
}

export function forgotPassword(request) {
  return function(dispatch) {
    dispatch(forgotPasswordRequest());

    let data = [];

    for (var key in request) {
      if (request.hasOwnProperty(key)) {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(request[key]));
      }
    }

    return fetch('https://app.testive.com/api/a/password_reset/',{
      method: 'POST',
      body: data.join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;'
      }
    }).then(
      response => response.json(),
      error => dispatch(forgotPasswordFailure(error))
    ).then(
      function(json) {
        if(json.status === 'ok') {
          dispatch(forgotPasswordSuccess(json));
        } else {
          dispatch(forgotPasswordFailure(json.msg));
        }
      }
    );
  };
}

function registerRequest() {
  return {
    type: REGISTER_REQUEST 
  };
}

function registerFailure(error) {
  return {
    type: REGISTER_FAILURE,
    error: error
  };
}

function registerSuccess(json, data) {
  return {
    type: REGISTER_SUCCESS,
    data: json.data,
    email: data.student_email,
  };
}

export function registerUser(request) {
  return function(dispatch) {
    dispatch(registerRequest());

    let data = [];

    for (var key in request) {
      if (request.hasOwnProperty(key)) {
        data.push(encodeURIComponent(key) + '=' + encodeURIComponent(request[key]));
      }
    }
    
    return fetch('https://app.testive.com/api/a/student_app_student_registration/',{
      method: 'POST',
      body: data.join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;'
      }
    }).then(
      response => response.json(),
      error => dispatch(registerFailure(error.message))
    ).then(
      function(json) {
        if(json.status === 'ok') {
          dispatch(registerSuccess(json, request));
        } else if (json.status === 'error') {
          dispatch(registerFailure(json.msg));
        }
      }
    );
  };
}
