import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forgotPassword } from '../../actions';

import Link from './../common/Link';
import styles from './ResetPassword.module.scss';
import ErrorMessage from './../common/ErrorMessage';

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showConfirmation: false,
    };

    this.submitReset = this.submitReset.bind(this);
  }
    
  submitReset(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.forgotPassword({email: this.state.email});
    this.setState({showConfirmation: true});
  }

  render() {
    return (
      <div className={styles.resetPassword}>
        <ErrorMessage errors={this.props.error}/>  
        { this.state.showConfirmation ? 
          <div className={styles.formContainer}>
            <div className={styles.title}>Reset your password</div>
            <div className={styles.subheader}>An email has been sent to your email address, {this.state.email}. Follow the directions in the email to reset your password.</div>
            <div className={styles.subheader}>After you follow the instructions to update your password you may return to login and use your new info to sign in.</div>
            <Link
              onPress={() => this.props.history.push('/login')}
              text='return to login'>
            </Link>
          </div>
          :
          <form className={styles.formContainer} onSubmit={this.submitReset}>
            <div className={styles.title}>Forgot your password?</div>
            <div className={styles.subheader}>Enter the email registered to your Testive account and we&#39;ll send a link to reset your password.</div>
            <input 
              className={styles.textInput}
              name='email'
              type='email'
              autoComplete='email'
              onChange={(e) => this.setState({email: e.target.value})}
              placeholder='email'
              required>
            </input>
            <div className={styles.buttonContainer}>
              <input className={styles.loginButton} type='submit' value='SEND EMAIL'></input>
            </div>

            <Link
              onPress={() => this.props.history.push('/login')}
              text='return to login'>
            </Link>
          </form>
        }
      </div>
    );
  }
}

ResetPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  history: PropTypes.object,
  error: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    errors: state.user.errors
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: request => dispatch(forgotPassword(request))
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
